import { apiFetch } from './api.js';
import { API_BASE_URL } from './constants.js';

/** @type {Map<number, JWEvent>} */
export const eventsCache = new Map();

const SERVER_API = new URL('jwevents/', API_BASE_URL);

// /** @return {JWEvents[]} */
// export function refreshJWEvents() {
//   return [...eventsCache.values()];
// }

/**
 * @param {string} filter
 * @return {Promise<JWEvent[]>}
 */
export async function queryJWEvents(filter = '') {
  const url = new URL(SERVER_API);
  if (filter) {
    url.searchParams.set('name', filter);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

export const refreshJWEvents = queryJWEvents;

/**
 * @param {number} eventId
 * @return {Promise<JWEvent|null>}
 */
export async function fetchJWEvent(eventId) {
  const url = new URL(eventId.toString(10), SERVER_API);
  const response = await apiFetch(url);
  if (response.ok) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} eventId
 * @return {Promise<boolean>} Returns true if deleted
 */
export async function deleteJWEvent(eventId) {
  const url = new URL(eventId.toString(10), SERVER_API);
  const response = await apiFetch(url, {
    method: 'DELETE',
  });

  if (response.status === 410) return true;

  return false;
}

/**
 * @param {number} eventId
 * @param {Partial<JWEvent>} patchInfo
 * @return {Promise<JWEvent>}
 */
export async function updateJWEvent(eventId, patchInfo) {
  const url = new URL(eventId.toString(10), SERVER_API);
  const response = await apiFetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
    body: JSON.stringify(patchInfo),
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<JWEvent>} addInfo
 * @return {Promise<JWEvent>}
 */
export async function createJWEvent(addInfo) {
  const url = new URL(SERVER_API);
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(addInfo),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<JWEventTicketRange>} filter
 * @return {Promise<JWEventTicketRange[]>}
 */
export async function queryJWEventTicketRanges({ eventId, ticketRangeId } = {}) {
  let url;
  if (eventId) {
    url = new URL(`jwevents/${eventId}/ticketranges/${ticketRangeId || ''}`, API_BASE_URL);
  } else if (ticketRangeId) {
    url = new URL(`ticketranges/${ticketRangeId}/jwevents/`, API_BASE_URL);
  } else {
    throw new Error('Invalid search.');
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {JWEventTicketRange} jwEventTicketRange
 * @return {Promise<any>}
 */
export async function putJWEventTicketRange(jwEventTicketRange) {
  const { eventId, ticketRangeId } = jwEventTicketRange;
  const url = new URL(`jwevents/${eventId}/ticketranges/${ticketRangeId || ''}`, API_BASE_URL);

  await apiFetch(url, {
    method: 'PUT',
    body: JSON.stringify(jwEventTicketRange),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
}

/**
 * @param {JWEventTicketRange} jwEventTicketRange
 * @return {Promise<any>}
 */
export async function deleteJWEventTicketRange(jwEventTicketRange) {
  const { eventId, ticketRangeId } = jwEventTicketRange;
  const url = new URL(`jwevents/${eventId}/ticketranges/${ticketRangeId || ''}`, API_BASE_URL);
  await apiFetch(url, { method: 'DELETE' });
}

/**
 * @param {number} eventId
 * @param {Partial<JWEventDelegate>} [filter]
 * @return {Promise<JWEventDelegate[]>}
 */
export async function queryJWEventDelegates(eventId, filter = {}) {
  const url = new URL(`jwevents/${eventId}/delegates`, API_BASE_URL);
  for (const [key, value] of Object.entries(filter)) {
    url.searchParams.set(key, value?.toString(10) ?? '');
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {number} eventId
 * @return {Promise<JWEventTemplate[]>}
 */
export async function queryJWEventTemplates(eventId) {
  const url = new URL(`jwevents/${eventId}/templates`, API_BASE_URL);

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {Partial<JWEventTemplate>} jwEventTemplate
 * @return {Promise<JWEventTemplate>}
 */
export async function createJWEventTemplate(jwEventTemplate) {
  const url = new URL(`jwevents/${jwEventTemplate.eventId}/templates`, API_BASE_URL);

  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(jwEventTemplate),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} eventId
 * @param {number} templateId
 * @return {Promise<JWEventTemplate[]>}
 */
export async function fetchJWEventTemplate(eventId, templateId) {
  const url = new URL(`jwevents/${eventId}/templates/${templateId}`, API_BASE_URL);

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  if (serverResponse.status === 404) return null;
  throw new Error(serverResponse.statusText);
}

/**
 * @param {number} eventId
 * @param {number} templateId
 * @param {Partial<JWEvent>} patchInfo
 * @return {Promise<JWEvent>}
 */
export async function updateJWEventTemplate(eventId, templateId, patchInfo) {
  const url = new URL(`jwevents/${eventId}/templates/${templateId}`, API_BASE_URL);
  const response = await apiFetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
    body: JSON.stringify(patchInfo),
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Object} options
 * @param {number} options.eventId
 * @param {number} options.templateId
 * @return {Promise<boolean>}
 */
export async function deleteJWEventTemplate({ eventId, templateId }) {
  const url = new URL(`jwevents/${eventId}/templates/${templateId}`, API_BASE_URL);
  const response = await apiFetch(url, {
    method: 'DELETE',
  });

  if (response.status === 410) return true;

  return false;
}

/**
 * @param {Object} options
 * @param {number} options.eventId
 * @param {number} options.templateId
 * @return {Promise<JWEventTemplate>}
 */
export async function sendJWEventTemplateEmailTickets({ eventId, templateId }) {
  const url = new URL(`jwevents/${eventId}/templates/${templateId}/emailtickets`, API_BASE_URL);

  const response = await apiFetch(url, { method: 'POST' });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Object} options
 * @param {number} options.eventId
 * @param {number} options.templateId
 * @return {URL | string}
 */
export function getTemplateSampleEmailURL({ eventId, templateId }) {
  return new URL(`jwevents/${eventId}/templates/${templateId}/sample.html`, API_BASE_URL);
}

/**
 * @param {Object} options
 * @param {number} options.eventId
 * @param {number} options.templateId
 * @return {URL | string}
 */
export function getTemplateSampleTicketURL({ eventId, templateId }) {
  return new URL(`jwevents/${eventId}/templates/${templateId}/sample.png`, API_BASE_URL);
}
