import { PALETTES } from '@shortfuse/materialdesignweb/services/theme.js';

import { addIconAliases, reportUnaliasedMaterialSymbols } from './icons.js';

PALETTES.push(
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
);

addIconAliases();

// setInterval(async () => {
// console.debug(JSON.stringify(await reportUnaliasedMaterialSymbols()));
// }, 1000);
