import EventDispatcherHandler from './EventDispatchHandler.js';

/**
 * @template T
 * @typedef {Object} Listener
 * @prop {TypedEventListener<T>} callback
 * @prop {AddEventListenerOptions} options
 */


/**
 * @template {any} T
 * @typedef {((this: EventTarget, event: (Event|CustomEvent<T>)) => void)} TypedEventListener
 */

/** @type {?EventDispatcher<any>} */
let defaultEventDispatcherInstance = null;

/**
 * @param {Function} classObject
 * @return {boolean}
 */
function hasConstructor(classObject) {
  if (!classObject || !classObject.prototype || !classObject.prototype.constructor) {
    return false;
  }
  try {
    Reflect.construct(classObject, []);
    return true;
  } catch {
    return false;
  }
}

/** @template T */
export default class EventDispatcher {
  /**
   * @param {string} [namespace]
   * @param {EventTarget} [eventTarget]
   */
  constructor(namespace, eventTarget) {
    this.namespace = namespace;
    /** @type {?EventTarget} */
    this.eventTarget = eventTarget ?? null;
    this.eventMap = new WeakMap();
  }

  /** @return {EventDispatcher<any>} */
  static get default() {
    if (!defaultEventDispatcherInstance) {
      defaultEventDispatcherInstance = new EventDispatcher('');
    }
    return defaultEventDispatcherInstance;
  }

  /**
   * @param {string} type
   * @return {string}
   */
  qualifyType(type) {
    if (!this.namespace) {
      return type;
    }
    return `${this.namespace}:${type}`;
  }

  /** @return {EventTarget} */
  getEventTarget() {
    return this.eventTarget ??= new EventTarget();
  }

  /**
   * @template T
   * @param {string} type
   * @param {T=} detail
   * @return {CustomEvent<T>}
   */
  createEvent(type, detail) {
    return new CustomEvent(this.qualifyType(type), {
      bubbles: true,
      cancelable: true,
      detail,
    });
  }

  /**
   * @param {CustomEvent<T>} event
   * @return {boolean}
   */
  dispatchEvent(event) {
    return this.getEventTarget().dispatchEvent(event);
  }

  /**
   * @template {TypedEventListener<T>|TypedEventListenerObject<T>} C
   * @param {string} type
   * @param {C} callback
   * @return {C}
   */
  addEventListener(type, callback) {
    this.getEventTarget().addEventListener(this.qualifyType(type), callback);
    return callback;
  }

  /**
   * @param {string} type
   * @param {TypedEventListener<T>} callback
   * @return {void}
   */
  removeEventListener(type, callback) {
    this.getEventTarget().removeEventListener(this.qualifyType(type), callback);
  }

  /**
   * @param {string} type
   * @return {EventDispatcherHandler<T>}
   */
  createHandler(type) {
    return new EventDispatcherHandler(this, type);
  }

  /**
   * @param {string} type
   * @param {T=} detail
   * @return {boolean}
   */
  emit(type, detail) {
    return this.dispatchEvent(this.createEvent(type, detail));
  }

  /**
   * @param {string} type
   * @param {function(T):any} callback
   * @return {function(T):any} callback
   */
  on(type, callback) {
    let fn = this.eventMap.get(callback);
    if (!fn) {
      /**
       * @param {{ detail: T; }} event
       * @return {any}
       */
      fn = (event) => callback(event.detail);
      this.eventMap.set(callback, fn);
    }
    this.addEventListener(type, fn);
    return callback;
  }

  /**
   * @param {string} type
   * @param {function(T):any} callback
   * @return {function(T):any} callback
   */
  once(type, callback) {
    let fn = this.eventMap.get(callback);
    if (!fn) {
      /**
       * @param {{ detail: T; }} event
       * @return {void}
       */
      fn = (event) => {
        callback(event.detail);
        this.off(type, callback);
      };
      this.eventMap.set(callback, fn);
    }
    this.addEventListener(type, fn);
    return callback;
  }

  /**
   * @param {string} type
   * @param {function(T):any} callback
   * @return {void}
   */
  off(type, callback) {
    const fn = this.eventMap.get(callback);
    if (fn) {
      this.removeEventListener(type, fn);
      this.eventMap.delete(callback);
    }
  }
}
