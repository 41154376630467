import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Fab.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Chip.js';
import '@shortfuse/materialdesignweb/components/SegmentedButtonGroup.js';
import '@shortfuse/materialdesignweb/components/SegmentedButton.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/Surface.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { queryGroups } from '../../delegate.js';

export default CustomElement
  .extend()
  .observe({
    list: {
      type: 'object',
      reflect: false,
      /** @type {DelegateGroup[]} */
      value: null,
    },
    busy: 'boolean',
    _searchFilter: 'string',
  })
  .set({
    _searchDebouncer: null,
  })
  .expressions({
    computedHref(data, { Group }) {
      if (!Group) return '';
      return `/groups/${Group.delegateGroupId}`;
    },
  })
  .html`
    <mdw-pane gap=16 x=stretch padding=pane>
      <mdw-search id=search placeholder="Groups">
        <mdw-icon-button slot=leading icon=menu href="#nav">Toggle Nav</mdw-icon-button>
      </mdw-search>
      <mdw-box grid id=list gap=16>
        <mdw-progress mdw-if={busy} circle></mdw-progress>
        <mdw-title text-trim mdw-if="${({ list, busy }) => Boolean(!busy && list && !list.length)}">No groups found.</mdw-title>
        <mdw-card mdw-if={!busy} mdw-for="{Group of list}" padding=16 gap=16 elevated actionable href={computedHref}>
          <mdw-title size=medium ink=primary text-trim>{Group.name}</mdw-title>
        </mdw-card>
      </mdw-box>
    </mdw-pane>
  `
  .css`
    mdw-page {
      display: grid;
    }

    
  `
  .methods({
    async update() {
      // fullarray
      this.list = null;
      this.list = await queryGroups({ name: this._searchFilter ? `%${this._searchFilter}%` : null });
    },
  })
  .childEvents({
    fab: {
      click(event) {
        console.log('Add Group');
      },
    },
    search: {
      input(event) {
        console.log(event.target);
        this._searchFilter = event.target.value.trim().toLowerCase();
        const scheduledSearchTerm = this._searchFilter;
        clearTimeout(this._searchDebouncer);
        this._searchDebouncer = setTimeout(async () => {
          // Abort if search term has changed during debouce (user input)
          if (this._searchFilter !== scheduledSearchTerm) return;
          this.busy = true;
          const filteredList = await queryGroups({ name: scheduledSearchTerm ? `%${scheduledSearchTerm}%` : null });
          // Abort if search term has changed during fetch
          if (this._searchFilter !== scheduledSearchTerm) return;
          this.list = filteredList; // In place update
          this.busy = false;
        }, 200);
      },
    },
    list: {
      click(event) {
        console.log(event.target);
        if (event.target.tagName === 'MDW-CARD') {
          console.log('action!');
        }
      },
    },
  })
  .on({
    connected() {
      this.update();
    },
  })
  .autoRegister('groups-list');
