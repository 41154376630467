import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Headline.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Listbox.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { createGroup } from '../../delegate.js';

import FilteredGroupTypesMixin from './FilteredGroupTypesMixin.js';

export default CustomElement
  .extend()
  .mixin(FilteredGroupTypesMixin)
  .observe({
    open: 'boolean',
  })
  .html`
    <mdw-page>
    <form id=form>
      <fieldset id=fieldset>
      <mdw-card x=stretch y=center padding=24 gap=24 elevated id=add-card>
        <mdw-headline text-trim>Add New Group</mdw-headline>
        <mdw-input id=group-name name=groupName label="Name" outlined required></mdw-input>
        <mdw-input id=group-type name=groupType label="Type" value="Other" outlined required autocomplete-inline autocomplete-list autosuggest-inline>
          <mdw-listbox>
            <mdw-list-option mdw-for="{type of _groupTypes}" value={type}>{type}</mdw-list-option>
            <mdw-list-option mdw-if={isNewGroupType} icon=add_circle icon-ink=primary label={_groupTypeFilter} value={_groupTypeFilter}>Create: {_groupTypeFilter}</mdw-list-item>
          </mdw-listbox>
        </mdw-input>
        <mdw-box row x=end gap=8>
          <mdw-button id=cancel filled=tonal>Cancel</mdw-button>
          <mdw-button id=save   type=submit filled>Save</mdw-button>
        </mdw-box>
      </mdw-card>
      </fieldset>
    </form>
    </mdw-page>
  `
  .css`
    mdw-page {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      block-size: 100%;
      padding: 24px;
    }

    #add-card {
      inline-size: 100%;
      max-inline-size: 80ch;
    }

    form, fieldset {
      display: contents;
    }
  `
  .methods({
    close() {
      this.dispatchEvent(new Event('close'));
      this.open = false;
    },
  })
  .childEvents({
    cancel: {
      click(event) {
        this.close();
      },
    },
    form: {
      async submit(event) {
        event.preventDefault();

        const form = /** @type {HTMLFormElement} */(event.currentTarget);
        const formData = new FormData(form);
        const groupValues = /** @type {Record<keyof DelegateGroup,string>} */ (Object.fromEntries(formData.entries()));
        console.log(groupValues);

        // build partial group record
        /** @type {Partial<DelegateGroup>} */
        const newGroup = {
          name: groupValues.groupName || null,
          type: groupValues.groupType || null,
        };

        console.log(newGroup);
        try {
          await createGroup(newGroup);
        } catch (e) {
          // Abort if user left already (eg: timeout)
          if (!this.open) return; // UI is gone
          console.error(e);
        }
        this.close();
      },
      keydown(event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
    },
    groupType: {
      input({ currentTarget }) {
        this._groupTypeFilter = currentTarget.value;
      },
    },
  })
  .on({
    openChanged(oldValue, open) {
      if (open) {
        // Reset on open
        const form = /** @type {HTMLFormElement} */ (this.refs.form);
        form.reset();
        this._groupTypeFilter = 'Other';
        // this.refreshGroupTypes();
      }
    },
  })
  .autoRegister('add-groups');
