import EventDispatcher from '../../utils/EventDispatcher.js';

import { API_BASE_URL } from './constants.js';

/** @typedef {'unknown'|'unauthorized'|'loggedin'} LoginState */

/**
 * @template T
 * @typedef {import( '../../utils/EventDispatchHandler.js').default<T>} EventDispatchHandler
 */

const authEventDispatcher = new EventDispatcher('app:auth');

/** @type {EventDispatchHandler<LoginState>} */
export const loginStateChangedEvent = authEventDispatcher.createHandler('loginstatechanged');

/** @type {EventDispatchHandler<Delegate>} */
export const profileUpdatedEvent = authEventDispatcher.createHandler('profileupdated');

/** @type {LoginState} */
let currentLoginState = 'unknown';

/** @type {any} */
let checkAuthInterval = null;

/** @return {LoginState} */
export function getLoginState() {
  return currentLoginState;
}

/** @type {Delegate} */
export const profile = {};

/**
 * @param {LoginState} loginState
 * @return {void}
 */
export function setLoginState(loginState) {
  if (loginState === currentLoginState) {
    return;
  }
  currentLoginState = loginState;
  loginStateChangedEvent.emit(loginState);
}

/**
 * Performs check against server to ensure user is still authorized
 * @return {Promise<boolean>}
 * @throws {Error}
 */
export async function checkAuth() {
  try {
    const response = await fetch(new URL('/api/profile', API_BASE_URL), {
      credentials: 'include',
      mode: 'cors',
    });
    if (response.status === 401) {
      setLoginState('unauthorized');
    } else if (response.ok) {
      const data = await response.json();
      Object.assign(profile, data);
      profileUpdatedEvent.emit(profile);
      setLoginState('loggedin');
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
    // Ignore errors
    // setLoginState('unauthorized');
  }
}

/** @return {void} */
function createCheckAuthInterval() {
  if (!checkAuthInterval) {
    checkAuthInterval = setInterval(checkAuth, 5 * 60 * 1000);
  }
}

/** @return {Promise<void>} */
export async function startAuthService() {
  createCheckAuthInterval();
  let complete = false;
  let retryDelay = 0;
  while (!complete) {
    // eslint-disable-next-line no-await-in-loop
    complete = await checkAuth();
    if (!complete) {
      console.warn('Retrying...');
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      retryDelay = retryDelay
        ? Math.max(30_000, retryDelay * 2)
        : 100;
    }
  }
}

/**
 * @param {string} username
 * @param {string} password
 */
export async function login(username, password) {
  const ac = new AbortController();
  setTimeout(() => ac.abort(), 15_000);

  const response = await fetch(new URL('/api/login', API_BASE_URL), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
    signal: ac.signal,

  });
  if (!response.ok) {
    const err = new Error(response.statusText);
    err.status = response.status;
    throw err;
  }

  const data = await response.json();
  Object.assign(profile, data);
  profileUpdatedEvent.emit(profile);
  setLoginState('loggedin');

  // TODO: Add interval check
}

/**
 * @param {string} currentPassword
 * @param {string} newPassword
 * @return {Promise<boolean>} changed
 */
export async function changePassword(currentPassword, newPassword) {
  const ac = new AbortController();
  setTimeout(() => ac.abort(), 15_000);

  const response = await fetch(new URL('/api/changepassword', API_BASE_URL), {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ currentPassword, newPassword }),
    signal: ac.signal,

  });
  return response.ok;
}

/** @return {Promise<void>} */
export async function logout() {
  await fetch(new URL('/api/logout', API_BASE_URL), {
    method: 'POST',
  });

  setLoginState('unauthorized');
}
