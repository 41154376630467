import '@shortfuse/materialdesignweb/components/Body.js';
import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import '@shortfuse/materialdesignweb/components/DialogActions.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Grid.js';
import '@shortfuse/materialdesignweb/components/Headline.js';
import '@shortfuse/materialdesignweb/components/IconButton.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/InputChip.js';
import '@shortfuse/materialdesignweb/components/List.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import '@shortfuse/materialdesignweb/components/Pane.js';
import '@shortfuse/materialdesignweb/components/Surface.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import '@shortfuse/materialdesignweb/components/TopAppBar.js';

import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { parseNullableNumber } from '../../../../utils/parse.js';
import { profile, profileUpdatedEvent } from '../../auth.js';
import { assignDelegateTicket, deleteGroupMember, emailDelegateTicket, fetchDelegate, getDelegateEvents, getGroups, getViewTicketURL, putGroupMember, queryDelegateTicketHistory, removeDelegateTicket, resetDelegatePassword, updateDelegate } from '../../delegate.js';
import { queryScans } from '../../scan.js';

export default CustomElement
  .extend()
  .set({
    /** @type {number} */
    lastFetchedId: null,
    /** @type {AbortController} */
    fetchAbortController: null,
  })
  .observe({
    uri: 'string',
    _delegateId: 'integer',
    infoEditable: 'boolean',
    accessEditable: 'boolean',
    tickets: {
      type: 'object',
      reflect: false,
      /** @type {DelegateTicketHistory[]} */
      value: null,
    },
    delegate: {
      type: 'object',
      reflect: false,
      /** @type {Delegate} */
      value: null,
    },
    activeTickets: {
      type: 'object',
      reflect: false,
      /** @type {DelegateTicketHistory[]} */
      value: null,
    },
    groups: {
      type: 'object',
      reflect: false,
      /** @type {DelegateGroup[]} */
      value: null,
    },
    _scans: {
      /** @type {Scan[]} */
      value: [],
    },
    _profile: {
      type: 'object',
      /** @type {Delegate} */
      value: null,
    },
    _events: {
      /** @type {Awaited<ReturnType<import('../../delegate.js').getDelegateEvents>>} */
      value: [],
    },
    _currentTicketId: 'string',
    _currentTicketTemplateId: 'integer',
  })
  .observe({
    _groupIds({ delegate }) {
      if (!delegate?.groups) return '';
      return delegate.groups.map((group) => group.delegateGroupId).join(',');
    },
    _currentTicketTemplates({ _currentTicketId, _events }) {
      if (!_currentTicketId || !_events) return [];

      return _events.flatMap((event) => {
        if (event.ticketIds.includes(_currentTicketId)) {
          return event.templates.map((template) => ({
            ...template,
            eventName: event.name,
          }));
        }
        return [];
      })
        .flat();
    },
  })
  .expressions({
    canEditInfo() {
      // Check user permisions
      return true;
    },
    canEditAccess() {
      // Check user permisions
      return true;
    },
    infoEditButtonText({ infoEditable }) {
      return infoEditable ? 'Discard' : 'Edit';
    },
    accessEditButtonText({ accessEditable }) {
      return accessEditable ? 'Discard' : 'Edit';
    },
    computeTicketHistoryInfo(data, { ticket }) {
      if (!ticket) return '';
      /** @type {DelegateTicketHistory} */
      const ticketInfo = ticket;
      const assignString = new Date(ticketInfo.assignTimestamp).toLocaleString();

      if (!ticketInfo.removeTimestamp) {
        return `Active since ${assignString}.`;
      }
      const removeString = new Date(ticketInfo.removeTimestamp).toLocaleString();
      return `Active from ${assignString} to ${removeString}.`;
    },
    ticketInk(data, { ticket }) {
      if (!ticket) return '';
      /** @type {DelegateTicketHistory} */
      const ticketInfo = ticket;
      if (ticketInfo.removeTimestamp) return 'red';
      return 'green';
    },
    ticketIcon(data, { ticket }) {
      if (!ticket) return '';
      /** @type {DelegateTicketHistory} */
      const ticketInfo = ticket;
      if (ticketInfo.removeTimestamp) return 'unpublished';
      return 'check_circle';
    },
    disableEmailButton(data, { ticket }) {
      console.log('checking email', data, ticket);
      if (data?.delegate?.email) return null;
      return '';
    },
    computeEventSupporting(data, { event }) {
      if (!event) return '';
      /** @type {Awaited<ReturnType<import('../../delegate.js').getDelegateEvents>>[0]} */
      const eventItem = event;
      return eventItem.ticketIds.join(', ');
    },
    computeDisabledEmailTicketButton({ _events }, { ticket }) {
      if (!ticket) return true;
      if (!_events) return true;
      const hasTemplateForTicket = _events
        .some((event) => event.ticketIds.includes(ticket.ticketId) && event.templates.length !== 0);
      return !hasTemplateForTicket;
    },
    computeViewTicketSrc({ _delegateId, _currentTicketId, _currentTicketTemplateId }) {
      if (!_delegateId) return null;
      if (!_currentTicketId) return null;
      if (!_currentTicketTemplateId) return null;
      return getViewTicketURL({
        delegateId: _delegateId,
        ticketId: _currentTicketId,
        templateId: _currentTicketTemplateId,
      }).href;
    },
    describeScan(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Unknown Location';
      return (`${scanObject?.eventName} (${scanObject?.checkpointName})`);
    },
    describeScan2(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Ticket not found in system.';
      if (scanObject.allowed) return `Ticket #${scanObject?.ticketId}`;
      return `Unrecognized Ticket #${scanObject?.ticketId}`;
    },
    describeScan3(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Scan Error';
      if (scanObject.allowed) return 'Valid';
      return 'No Entry';
    },
  })
  .html`
    <mdw-pane id=pane flex-1>
      <mdw-box mdw-if={!uri} flex-1 x=center y=center>
        <mdw-headline padding-y=16><mdw-icon icon=person>Person</mdw-icon>No user selected.</mdw-headline>
      </mdw-box>
      <mdw-grid padding=pane mdw-if={!!uri} gap=16>
        <mdw-top-app-bar mdw-if={!!uri}>
          <mdw-icon-button slot="leading" icon="arrow_back" tabindex="0" on-click={onBack}>Back</mdw-icon-button>
          <span>Guest&nbsp;[<mdw-box block inline ink=primary>&nbsp;{delegate.delegateId}</mdw-box>]</span>
          <mdw-icon-button slot="trailing" icon="refresh" tabindex="0" on-click="{refreshDelegate}">Refresh</mdw-icon-button>
        </mdw-top-app-bar>
        <mdw-card id=info-card elevated={!infoEditable} outlined={infoEditable} gap=16 padding=16 col-span=4>
          <form id=info-form>
            <mdw-box row x=between y=start>
              <mdw-headline>Info</mdw-headline>
              <mdw-box mdw-if={canEditInfo} row x=between y=start ink=primary gap=8 >
                <mdw-button id=info-edit-button  type=button filled=tonal mdw-if={!infoEditable}  >Edit</mdw-button>
                <mdw-button id=info-reset-button type=reset  filled=tonal mdw-if={infoEditable}   >Discard</mdw-button>
                <mdw-button id=info-save-button  type=submit filled       disabled={!infoEditable}>Save</mdw-button>
              </mdw-box>
            </mdw-box>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=label       value={delegate.label}       label="Name"></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=email       value={delegate.email}       label="Email"        type=email></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=phoneNumber value={delegate.phoneNumber} label="Phone Number" type=tel maxlength=15></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=group_ids   value={_groupIds}            label="Groups"
            autocomplete-inline autocomplete-list autosuggest-inline multiple>
              <mdw-listbox multiple>
                <mdw-list-option mdw-for="{group of groups}" value={group.delegateGroupId} supporting="{group.type}">{group.name}</mdw-list-option>
              </mdw-listbox>
            </mdw-input>
          </form>
        </mdw-card>
        <mdw-card id=access-card mdw-if={_profile.roleSystemAdmin} elevated={!accessEditable} outlined={accessEditable} gap=16 padding=16 col-span=4>
          <form id=access-form>
            <mdw-box row x=between y=start>
              <mdw-headline>Access</mdw-headline>
              <mdw-box mdw-if={canEditAccess} row x=between y=start ink=primary gap=8>
                <mdw-button id=access-edit-button  type=button filled=tonal mdw-if={!accessEditable}  >Edit</mdw-button>
                <mdw-button id=access-reset-button type=reset  filled=tonal mdw-if={accessEditable}   >Discard</mdw-button>
                <mdw-button id=access-save-button  type=submit filled       disabled={!accessEditable}>Save</mdw-button>
              </mdw-box>
            </mdw-box>
            <mdw-box row y=center gap=8>
              <mdw-input name=username flex-1 readonly={!accessEditable} outlined={accessEditable} filled={!accessEditable} value={delegate.username} id=info-username label="Username"></mdw-input>
              <mdw-icon-button id=reset-password-button filled=tonal icon="lock_reset">Reset Password</mdw-icon-button>
            </mdw-box>
            <mdw-filter-chip name=active          readonly={!accessEditable} outlined={rolesEditable} checked={!!delegate.active}          >Active</mdw-filter-chip>
            <mdw-filter-chip name=roleSystemAdmin readonly={!accessEditable} outlined={rolesEditable} checked={!!delegate.roleSystemAdmin} >System Admin</mdw-filter-chip>
            <mdw-filter-chip name=roleAdmin       readonly={!accessEditable} outlined={rolesEditable} checked={!!delegate.roleAdmin}       >Admin</mdw-filter-chip>
            <mdw-filter-chip name=roleTicketAgent readonly={!accessEditable} outlined={rolesEditable} checked={!!delegate.roleTicketAgent} >Ticket Agent</mdw-filter-chip>
            <mdw-filter-chip name=roleScanner     readonly={!accessEditable} outlined={rolesEditable} checked={!!delegate.roleScanner}     >Ticket Scanner</mdw-filter-chip>
            <!-- <mdw-filter-chip name=rolePhoto       outlined={rolesEditable} checked={!!delegate.rolePhoto}       readonly={!rolesEditable}>Photographer</mdw-filter-chip> -->
          </form>
        </mdw-card>
        <mdw-card id=tickets-card outlined gap=0 padding=0 col-span=4>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Tickets</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=ticket-add-button icon=add_circle>Add</mdw-icon-button>
              <mdw-icon-button id=ticket-refresh-button icon=refresh>Refresh</mdw-icon-button>
            </mdw-box>
          </mdw-box>
          <mdw-surface id=ticket-tab-surface shape-style=medium shape-bottom>
            <mdw-tab-list sticky-parent tab-content-id="tickets-tabcontent">
              <mdw-tab href="#tickets-assignment">Assignments</mdw-tab>
              <mdw-tab href="#tickets-history">Scan History</mdw-tab>
            </mdw-tab-list>
            <mdw-tab-content id="tickets-tabcontent">
              <mdw-tab-panel flex=column x=stretch color=surface-container id=tickets-assignment>
              <mdw-box mdw-if={!tickets.length} x=center y=center padding=24>
                  No ticket assignments found.
                </mdw-box>
                <mdw-list mdw-if={!!tickets.length}>
                  <mdw-list-item mdw-for="{ticket of tickets}" class=ticket-active-list-item icon={ticketIcon} icon-ink={ticketInk}>
                    <span slot>{ticket.ticketId}</span>
                    <mdw-box block inline type-style="body-small" slot="supporting">{computeTicketHistoryInfo}</mdw-box>
                    <mdw-box mdw-if={!ticket.removeTimestamp} slot=trailing row gap=8 data-ticket-id={ticket.ticketId} on-click={onTicketListItemTrailingClick}>
                      <mdw-icon-button icon=email  aria-haspopup=dialog data-action=email  disabled={computeDisabledEmailTicketButton}>Email</mdw-icon-button>
                      <mdw-icon-button icon=cancel aria-haspopup=dialog data-action=remove                                            >Remove</mdw-icon-button>
                    </mdw-box>
                  </mdw-list-item>
                </mdw-list>
              </mdw-tab-panel>
              <mdw-tab-panel flex=column x=stretch color=secondary-container id=tickets-history>
              <mdw-box mdw-if={!_scans.length} x=center y=center padding=24>
                  No scan history.
                </mdw-box>
                <mdw-list mdw-if={!!_scans.length}>
                <mdw-list-item mdw-for="{scan of _scans}" class=scan-list-item>
                    {describeScan}
                    <mdw-inline slot="trailing" ink="primary">${(data, { scan }) => (scan ? new Date(scan.timestamp).toLocaleString() : '')} </mdw-inline>
                    <mdw-inline slot="supporting">
                      <mdw-body ink=secondary size=medium>{describeScan2}</mdw-body>
                      <mdw-body ink=tertiary size=small>{describeScan3}</mdw-body>
                    </mdw-inline>
                  </mdw-list-item>
                </mdw-list>
              </mdw-tab-panel>
            </mdw-tab-content>
          </mdw-surface>
        </mdw-card>
        <mdw-card id=events-card elevated col-span=4>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Events</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=events-refresh-button icon=refresh on-click={refreshEvents}>Refresh</mdw-icon-button>
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box mdw-if={!_events.length} x=center y=center padding=24>
            No Events
          </mdw-box>
          <mdw-list mdw-if={!!_events.length}>
            <mdw-list-item mdw-for="{event of _events}" supporting={computeEventSupporting}>
              <span slot>{event.name}</span>
            </mdw-list-item>
          </mdw-list>
        </mdw-card>
      </mdw-grid>
    </mdw-pane>
    <mdw-dialog id=dialog-reset-password icon=lock_reset headline="Reset Password" confirm="Reset" cancel="Close" default="confirm">
      Reset password with username&nbsp;<mdw-body inline large ink=error>{delegate.username}</mdw-body>?
    </mdw-dialog>
    <mdw-dialog id=dialog-email-ticket icon=badge headline="Email Ticket" confirm="Remove" cancel="Close" default="confirm">
      <form id=dialog-email-ticket-form method="dialog">
        <mdw-box gap=16>
          <img id=dialog-email-ticket-img width=300 height=500 src={computeViewTicketSrc}>
          <mdw-input id=dialog-email-ticket-template type=select-one name=template_id label="Template" outlined required>
            <mdw-listbox>
              <mdw-list-option mdw-for="{template of _currentTicketTemplates}" value={template.templateId} supporting={template.eventName}>{template.name}</mdw-list-option>
            </mdw-listbox>
          </mdw-input>
        </mdw-box>
      </form>
      <div slot=actions>
        <input type="submit" form=dialog-email-ticket-form hidden value=cancel tabindex=-1 aria-hidden="true" >
        <!-- Forms submit nearest type=submit control on ENTER key -->
        <mdw-button form=dialog-email-ticket-form type=submit value=cancel formnovalidate>Close</mdw-button>
        <mdw-button form=dialog-email-ticket-form type=submit value=confirm disabled={!delegate.email}>Email</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-remove-ticket icon=cancel headline="Remove Ticket" confirm="Remove" cancel="Close" default="confirm">
      Remove ticket number&nbsp;<mdw-body inline large ink=error>{_currentTicketId}</mdw-body>?
    </mdw-dialog>
    <mdw-dialog id=dialog-assign-ticket icon=add_circle headline="Assign Ticket" default="confirm">
      <form id=assign-ticket-form method="dialog">
        <mdw-input id=dialog-assign-ticket-id supporting name="ticketId" autofocus filled label="Ticket Number" required></mdw-input>
      </form>
      <div slot=actions>
          <input type="submit" form=assign-ticket-form hidden value=confirm tabindex=-1 aria-hidden="true" >
          <!-- Forms submit nearest type=submit control on ENTER key -->
          <mdw-button form=assign-ticket-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=assign-ticket-form type=submit value=confirm>Assign</mdw-button>
      </div>
    </mdw-dialog>
  `
  .css`
    :host {
      flex: 1;
    }

    form {
      display: contents;
    }

    #pane {
      display: flex;
      flex-direction: column;
    }

    .ticket-active-list-item {
      padding-inline: 16px;
    }

    #ticket-tab-surface {
      overflow: auto;

      min-block-size: 96px;
      flex: 1 0 360px;

      z-index: -1;
    }

    #group-row {
      gap: 16px 8px;
    }

    #dialog-email-ticket-img {
      max-block-size: 50vh;
      max-block-size: 50dvh;
      max-inline-size: 100%;

      object-fit: contain;
    }
  `
  .methods({
    onBack() { window.history.back(); },
    async refreshTickets() {
      const tickets = await queryDelegateTicketHistory({
        delegateId: this._delegateId,
      });
      this.tickets = tickets.sort((a, b) => (b.assignTimestamp - a.assignTimestamp));
    },
    async refreshEvents() {
      this._events = await getDelegateEvents(this._delegateId);
    },
    async refreshScans() {
      this._scans = await queryScans({ delegateId: this._delegateId });
    },
    async refreshDelegate() {
      if (!this.uri) return;
      try {
        // Convert URI to number
        const id = Number.parseInt(this.uri, 10);
        this._delegateId = id;
        // Fetch record from Server using delegate service
        const [delegate, groups] = await Promise.all([
          fetchDelegate(id),
          getGroups(),
          this.refreshEvents(),
          this.refreshScans(),
          this.refreshTickets(),
        ]);

        // Attach object to current element state
        this.delegate = delegate;
        this.groups = groups.sort((a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name));

        // Reset forms
        this.refs.infoForm.reset();
        this.refs.accessForm.reset();
        // this.refs.contactForm.reset();
        // this.busy = false;
      } catch (e) {
        console.error(e);
      }
    },
    /** @param {PointerEvent} event */
    onTicketListItemTrailingClick(event) {
      const element = /** @type {HTMLElement} */ (event.currentTarget);
      const target = /** @type {HTMLAnchorElement} */ (event.target);
      this._currentTicketId = element.dataset.ticketId;
      switch (target.dataset.action) {
        case 'remove':
          this.refs.dialogRemoveTicket.showModal();
          break;
        case 'email':
          this._currentTicketTemplateId = this._currentTicketTemplates[0]?.templateId;
          this.refs.dialogEmailTicket.showModal();
          this.refs.dialogEmailTicketForm.reset();
          this.refs.dialogEmailTicketTemplate.value = this._currentTicketTemplates[0].templateId;
          break;
        default:
      }
    },
  })
  .childEvents({
    infoForm: {
      async submit(event) {
        event.preventDefault();
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        const formData = new FormData(form);
        const entries = [...formData.entries()];
        const updateInfo = Object.fromEntries(entries);
        const tasks = [
          updateDelegate(this.delegate.delegateId, updateInfo),
        ];

        const oldGroupIds = this.delegate.groups.map((group) => group.delegateGroupId);
        const newGroupIds = updateInfo.group_ids.split(',').filter(Boolean).map((s) => Number.parseInt(s, 10));
        for (const id of newGroupIds) {
          if (oldGroupIds.includes(id)) continue;
          const groupMember = { delegateId: this.delegate.delegateId, delegateGroupId: id };
          tasks.push(putGroupMember(groupMember));
        }
        for (const id of oldGroupIds) {
          if (newGroupIds.includes(id)) continue;
          const groupMember = { delegateId: this.delegate.delegateId, delegateGroupId: id };
          tasks.push(deleteGroupMember(groupMember));
        }
        await Promise.all(tasks);

        await this.refreshDelegate();
        this.infoEditable = false;
      },
      reset() {
        this.infoEditable = false;
      },
      keydown(event) {
        if (event.key === 'Escape') {
          if (!this.infoEditable) return;
          event.preventDefault();
          const form = /** @type {HTMLFormElement} */ (event.currentTarget);
          form.reset();
        }
      },
    },
    infoEditButton: {
      click() {
        this.infoEditable = true;
      },
    },
    accessForm: {
      async submit(event) {
        event.preventDefault();
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        const formData = new FormData(form);
        const delegateValues = /** @type {Record<keyof Delegate,string>} */ (Object.fromEntries(formData.entries()));

        // Checkbox state must be captured manually
        /** @type {Partial<Delegate>} */
        const delegateInfo = {
          username: delegateValues.username,
          active: delegateValues.active === 'on',
          roleSystemAdmin: delegateValues.roleSystemAdmin === 'on',
          roleAdmin: delegateValues.roleAdmin === 'on',
          roleTicketAgent: delegateValues.roleTicketAgent === 'on',
          roleScanner: delegateValues.roleScanner === 'on',
          // rolePhoto: delegateValues.rolePhoto === 'on',
        };
        // Convert checkboxes to boolean
        await updateDelegate(this.delegate.delegateId, delegateInfo);
        await this.refreshDelegate();
        this.accessEditable = false;
      },
      reset() {
        this.accessEditable = false;
      },
      keydown(event) {
        if (event.key === 'Escape') {
          if (!this.accessEditable) return;
          event.preventDefault();
          const form = /** @type {HTMLFormElement} */ (event.currentTarget);
          form.reset();
        }
      },
    },
    accessEditButton: {
      click() {
        this.accessEditable = true;
      },
    },
    resetPasswordButton: {
      click() {
        this.refs.dialogResetPassword.showModal();
      },
    },
    ticketRefreshButton: {
      click() {
        this.refreshTickets().catch(() => {});
      },
    },
    ticketAddButton: {
      click() {
        this.refs.dialogAssignTicket.showModal();
        this.refs.assignTicketForm.reset();
        this.refs.assignTicketForm.disabled = false;
      },
    },
    dialogEmailTicketTemplate: {
      change(event) {
        const element = /** @type {HTMLSelectElement} */ (event.currentTarget);
        this._currentTicketTemplateId = parseNullableNumber(element.value);
      },
    },
    dialogAssignTicket: {
      input({ target }) { return target.setCustomValidity(''); },
      change({ target }) { return target.setCustomValidity(''); },
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault(); // Handle close async
        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);
        const { ticketId: ticketIdElement } = form.elements;
        const ticketId = ticketIdElement.value;
        const { delegateId } = this.delegate;
        const response = await assignDelegateTicket({ delegateId, ticketId });
        if (response) {
          dialog.close();
          await Promise.all([
            this.refreshTickets(),
            this.refreshEvents(),
          ]);
        } else {
          ticketIdElement.setCustomValidity('Ticket number already in use.');
          ticketIdElement.reportValidity();
        }
      },
    },
    dialogResetPassword: {
      async close(event) {
        /** @type {HTMLDialogElement} */
        const dialog = event.currentTarget;
        if (dialog.returnValue !== 'confirm') return;
        try {
          await resetDelegatePassword(this.delegate.delegateId);
        } catch {}
      },
    },
    dialogRemoveTicket: {
      async close(event) {
        /** @type {HTMLDialogElement} */
        const dialog = event.currentTarget;
        if (dialog.returnValue !== 'confirm') return;
        try {
          await removeDelegateTicket({
            delegateId: this.delegate.delegateId,
            ticketId: this._currentTicketId,
          });
        } catch {}
        this.refreshTickets();
      },
    },
    dialogEmailTicket: {
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        event.preventDefault();

        try {
          await emailDelegateTicket({
            delegateId: this.delegate.delegateId,
            templateId: this._currentTicketTemplateId,
            ticketId: this._currentTicketId,
          });
        } catch {}
        dialog.close();
        this._currentTicketTemplateId = null;
      },
    },
  })
  .on({
    uriChanged(oldValue, newValue) {
      this.refreshDelegate().catch(() => {});
    },
    constructed() {
      this._profile = { ...profile };
      profileUpdatedEvent.on((newProfile) => {
        this._profile = { ...newProfile };
      });
    },
    infoEditableChanged(oldValue, isEditable) {
      if (isEditable) {
        const form = /** @type {HTMLFormElement} */(this.refs.infoForm);
        const input = /** @type {HTMLInputElement} */ (form.elements.namedItem('label'));
        input?.focus();
        input?.select();
      } else if (this.refs.infoResetButton.matches(':focus')) {
        this.refs.infoEditButton.focus();
      }
    },
    accessEditableChanged(oldValue, isEditable) {
      if (isEditable) {
        const form = /** @type {HTMLFormElement} */(this.refs.accessForm);
        const input = /** @type {HTMLInputElement} */ (form.elements.namedItem('username'));
        input?.focus();
        input?.select();
      } else if (this.refs.accessResetButton.matches(':focus')) {
        this.refs.accessEditButton.focus();
      }
    },
  })
  .autoRegister('delegates-detail');
