import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import '@shortfuse/materialdesignweb/components/DialogActions.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/IconButton.js';
import '@shortfuse/materialdesignweb/components/List.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import '@shortfuse/materialdesignweb/components/Listbox.js';
import '@shortfuse/materialdesignweb/components/ListOption.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Pane.js';
import '@shortfuse/materialdesignweb/components/Surface.js';
import '@shortfuse/materialdesignweb/components/SegmentedButtonGroup.js';
import '@shortfuse/materialdesignweb/components/SegmentedButton.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import '@shortfuse/materialdesignweb/components/TextArea.js';
import '@shortfuse/materialdesignweb/components/TopAppBar.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { deleteGroupMember, fetchGroup, getGroupMembers, putGroupMember, queryDelegates, refreshDelegates, updateGroup } from '../../delegate.js';
import { queryScans } from '../../scan.js';

import FilteredGroupTypesMixin from './FilteredGroupTypesMixin.js';

export default CustomElement
  .extend()
  .mixin(FilteredGroupTypesMixin)
  .observe({
    uri: 'string',
  })
  .set({
    /** @type {number} */
    lastFetchedId: null,
    /** @type {AbortController} */
    fetchAbortController: null,
    /** @type {DelegateGroup} */
    group: null,
    /** Timeout used for debouncing text input */
    _memberInputDebouncer: null,
  })
  .observe({
    infoEditable: 'boolean',
    groupMembers: {
      type: 'object',
      reflect: false,
      /** @type {DelegateGroupMember[]} */
      value: null,
    },
    scans: {
      type: 'object',
      reflect: false,
      /** @type {Scan[]} */
      value: null,
    },
    users: {
      type: 'object',
      reflect: false,
      /** @type {Delegate[]} */
      value: null,
    },
    _currentMemberId: 'string',
    _removeMemberName: 'string',
    _memberFilter: 'string',
    _currentSelectedId: 'integer',
    addingMember: 'boolean',
    searchMembers: 'boolean',
  })
  .expressions({
    canEditInfo() {
      // Check user permisions
      return true;
    },
    infoEditButtonText({ infoEditable }) {
      return infoEditable ? 'Discard' : 'Edit';
    },
    computeDelegateLabel(data, { member }) {
      return member?.delegateLabel ?? 'Delegate';
    },
    computedHref(data, { member }) {
      if (!member) return '';
      return `/delegates/${member.delegateId}`;
    },
    computedHref2(data, { scan }) {
      if (!scan) return '';
      return `/delegates/${scan.delegateId}`;
    },
  })
  .html`
    <mdw-pane padding=pane id=pane flex-1>
      <mdw-box mdw-if={!uri} flex-1 x=center y=center>
        <mdw-headline padding-y=16><mdw-icon icon=groups>Group</mdw-icon>No group selected.</mdw-headline>
      </mdw-box>
      <mdw-top-app-bar mdw-if={!!uri}>
        <mdw-icon-button class="toolbar-icon-button" slot="leading" icon="arrow_back" tabindex="0" on-click={onBack}>Back</mdw-icon-button>
        <span><mdw-inline ink=primary>{group.name}<mdw-inline></span>
      </mdw-top-app-bar>
      <mdw-box mdw-if={!!uri} flex-1 padding-y=16 gap=16>
        <mdw-card id=info-card elevated={!infoEditable} outlined={infoEditable} gap=16 padding=16>
          <form id=info-form>
            <mdw-box row x=between y=start>
              <mdw-headline>Info</mdw-headline>
              <mdw-box mdw-if={canEditInfo} row x=between y=start ink=primary gap=8 >
                <mdw-button id=info-edit-button  type=button filled=tonal mdw-if={!infoEditable}  >Edit</mdw-button>
                <mdw-button id=info-reset-button type=reset  filled=tonal mdw-if={infoEditable}   >Discard</mdw-button>
                <mdw-button id=info-save-button  type=submit filled       disabled={!infoEditable}>Save</mdw-button>
              </mdw-box>
            </mdw-box>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=name value={group.name} id=info-name label="Group Name" required></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=type value={group.type} id=info-type label="Type"  required autocomplete-inline autocomplete-list autosuggest-inline>
              <mdw-listbox>
                <mdw-list-option mdw-for="{type of _groupTypes}" value={type}>{type}</mdw-list-option>
                <mdw-list-option mdw-if={isNewGroupType} icon=add_circle icon-ink=primary label={_groupTypeFilter} value={_groupTypeFilter}>Create: {_groupTypeFilter}</mdw-list-item>
              </mdw-listbox>
            </mdw-input>
          </form>
        </mdw-card>
      </mdw-box>
      <mdw-box mdw-if={!!uri} flex-1 padding-y=16 gap=16>
        <mdw-card id=members-card elevated gap=16 padding=16>
            <mdw-box row x=between y=start padding=16>
              <mdw-headline>Group Members</mdw-headline>
              <mdw-box row x=between ink=primary gap=8>
                <mdw-icon-button mdw-if={!addingMember} id=members-search-button icon=search>Search</mdw-icon-button>
                <mdw-icon-button mdw-if={!addingMember} id=members-add-button icon=add_circle>Add</mdw-icon-button>
                <mdw-icon-button mdw-if={!addingMember} id=members-refresh-button icon=refresh>Refresh</mdw-icon-button>              
              </mdw-box>
            </mdw-box>
            <mdw-box mdw-if={searchMembers} row x=start gap=16>
              <mdw-input mdw-if={searchMembers} id=search-input label="Search Name" outlined autocomplete-inline autocomplete-list autosuggest-inline>
                  <mdw-listbox id=search-listbox>
                    <mdw-list-option mdw-for="{user of users}" value={user.delegateId}>{user.label}</mdw-list-option>
                  </mdw-listbox>
              </mdw-input>
              <mdw-button id=search-member-cancel filled=tonal>Cancel</mdw-button>
            </mdw-box>
            <form mdw-if={addingMember} id=assign-member-form>
              <mdw-input id=member-input label="Member Name" outlined autocomplete-inline autocomplete-list autosuggest-inline>
                  <mdw-listbox id=member-listbox>
                    <mdw-list-option mdw-for="{user of users}" value={user.delegateId}>{user.label}</mdw-list-option>
                  </mdw-listbox>
              </mdw-input>
              <mdw-box row x=end gap=8>
                <mdw-button id=assign-member-cancel filled=tonal>Cancel</mdw-button>
                <mdw-button type="submit" filled name=confirm>Assign</mdw-button>
              </mdw-box>
            </form>
            <mdw-surface mdw-if={!addingMember} id=members-tab-surface shape-style=medium shape-bottom>
              <mdw-tab-list sticky-parent tab-content-id="members-tabcontent">
                <mdw-tab href="#members-active">Members</mdw-tab>
                <mdw-tab href="#members-history">Scan History</mdw-tab>
              </mdw-tab-list>
              <mdw-tab-content id="members-tabcontent">
                <mdw-tab-panel flex=column x=stretch color=surface-container id=members-active>
                  <mdw-list>
                    <mdw-list-item mdw-for="{member of groupMembers}" class=ticket-active-list-item href={computedHref}>
                      <span slot>{computeDelegateLabel}</span>
                      <mdw-box slot=trailing row gap=8>
                      <mdw-icon-button icon=cancel data-member-id={member.delegateId} data-member-name={computeDelegateLabel} on-click={onRemoveMemberClick} >Remove</mdw-icon-button>
                      </mdw-box>
                    </mdw-list-item>
                  </mdw-list>
                </mdw-tab-panel>
                <mdw-tab-panel flex=column x=stretch color=secondary-container id=members-history>
                  <mdw-list>
                    <mdw-list-item mdw-for="{scan of scans}" icon="{scanIcon}" icon-ink="{scanInk}" href={computedHref2}>
                      <span slot>{scan.delegateLabel} - ${(data, { scan }) => (scan ? new Date(scan.timestamp).toLocaleString() : '')}</span>
                      <mdw-box block inline type-style="body-small" ink="primary" slot="supporting">{scan.eventName} | {scan.checkpointName}</mdw-box>
                    </mdw-list-item>
                  </mdw-list>
                </mdw-tab-panel>
              </mdw-tab-content>
            </mdw-surface>
          </mdw-card>
    </mdw-pane>
    <mdw-dialog id=dialog-remove-member icon=cancel headline="Remove Group Member" confirm="Remove" cancel="Close" default="confirm">
      Remove :&nbsp;<mdw-body inline large ink=error>{_removeMemberName}</mdw-body>?
    </mdw-dialog>
  `
  .css`
    :host {
      flex: 1;
    }

    form {
      display: contents;
    }

    #pane {
      display: flex;
      flex-direction: column;
    }
  `
  .methods({
    onBack() { window.history.back(); },
    async refreshMembers() {
      const groupMember = {
        delegateGroupId: this.group.delegateGroupId,
      };
      this.groupMembers = await getGroupMembers(groupMember);
    },
    async filterMembers() {
      console.log('currentSelectedId:', this._currentSelectedId);
      const groupMember = {
        delegateId: this._currentSelectedId,
      };
      this.groupMembers = await getGroupMembers(groupMember);
    },
    async refreshGroup() {
      if (!this.uri) return;
      try {
        // Convert URI to number
        const id = Number.parseInt(this.uri, 10);
        // Fetch record from Server using Group service
        const groupMember = {
          delegateGroupId: id,
        };

        const scan = {
          delegateGroupId: id,
        };

        const [group, groupMembers, users] = await Promise.all([
          fetchGroup(id),
          getGroupMembers(groupMember),
          refreshDelegates(),
          this.refreshGroupTypes(),
        ]);

        const scans = await queryScans({ groupType: group.type, groupName: group.name });

        // Attach object to current element state
        this.group = group;

        // Update all HTML
        this.render({ group: this.group });
        this.groupMembers = groupMembers;
        console.log(groupMembers);
        this.users = users;
        console.log(users);
        this.scans = scans;
        console.log(scans);

        // Reset forms
        this.refs.infoForm.reset();
      } catch (e) {
        console.error(e);
      }
    },
    /** @param {PointerEvent} event */
    onRemoveMemberClick(event) {
      /** @type {HTMLButtonElement} */
      const element = event.currentTarget;
      console.log('_currentMemberId:', element.dataset.memberId);
      console.log('_removeMemberName:', element.dataset.memberName);
      this._currentMemberId = element.dataset.memberId;
      this._removeMemberName = element.dataset.memberName;
      this.refs.dialogRemoveMember.showModal();
    },
  })
  .childEvents({
    infoType: {
      input({ currentTarget }) {
        this._groupTypeFilter = currentTarget.value;
      },
    },
    infoForm: {
      async submit(event) {
        event.preventDefault();
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        const formData = new FormData(form);
        /** @type {Partial<DelegateGroup>} */
        const updateInfo = Object.fromEntries(formData.entries());
        console.log(updateInfo);
        console.log(this.group.delegateGroupId);

        await updateGroup(this.group.delegateGroupId, updateInfo);

        await this.refreshGroup();
        this.infoEditable = false;
      },
      reset() {
        this.infoEditable = false;
      },
      keydown(event) {
        if (event.key === 'Escape') {
          if (!this.infoEditable) return;
          event.preventDefault();
          const form = /** @type {HTMLFormElement} */ (event.currentTarget);
          form.reset();
        }
      },
    },
    infoEditButton: {
      click() {
        this.infoEditable = true;
      },
    },
    membersSearchButton: {
      click() {
        this.addingMember = false;
        this.searchMembers = true;
      },
    },
    searchMemberCancel: {
      click() {
        this.searchMembers = false;
        this.refreshMembers();
      },
    },
    membersRefreshButton: {
      click() {
        this.refreshMembers().catch(() => {});
      },
    },
    membersAddButton: {
      click() {
        this.refs.assignMemberForm.reset();
        this.refs.assignMemberForm.disabled = false;
        this.searchMembers = false;
        this.addingMember = true;
      },
    },
    assignMemberCancel: {
      click() {
        this.addingMember = false;
      },
    },
    assignMemberForm: {
      async submit(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        console.log(event);
        /** @type {HTMLFormElement} */
        const groupMember = { delegateId: this._currentSelectedId, delegateGroupId: this.group.delegateGroupId };
        const response = await putGroupMember(groupMember);
        this.refreshMembers();
        this.addingMember = false;
      },
    },
    dialogRemoveMember: {
      async close(event) {
        /** @type {HTMLDialogElement} */
        const dialog = event.currentTarget;
        if (dialog.returnValue !== 'confirm') return;
        try {
          await deleteGroupMember({
            delegateId: Number(this._currentMemberId),
            delegateGroupId: this.group.delegateGroupId,
          });
        } catch {}
        this.refreshMembers();
      },
    },
    memberInput: {
      input(event) {
        // Cast element to <input>
        const memberInput = /** @type {HTMLInputElement} */ (event.currentTarget);
        // get Element "value"
        const currentValue = memberInput.value;
        // Reset error state
        memberInput.setCustomValidity('');

        // Cast listbox to <select>
        const memberListbox = /** @type {HTMLSelectElement} */ (this.refs.memberListbox);
        // If listbox has a selection, use value from listbox ()
        if (memberListbox.selectedIndex !== -1) {
          // Has valid input, read value as number and set as eventId
          const numberValue = Number.parseInt(currentValue, 10);
          if (Number.isNaN(numberValue) === false) {
            this._currentSelectedId = numberValue;
            return;
          }
        }
        // Perform search instead

        // No event selected
        this._currentSelectedId = null;
        // Use current input as filter for search
        this._memberFilter = currentValue.trim().toLowerCase();

        // Store search term
        const scheduledSearchTerm = this._memberFilter;

        // Clear any existing debouncer
        clearTimeout(this._memberInputDebouncer);

        // Schedule search with 200ms debouncer
        this._memberInputDebouncer = setTimeout(async () => {
          // Abort if search term has changed during debouce (user input)
          if (this._memberFilter !== scheduledSearchTerm) return;
          const filteredList = await queryDelegates(scheduledSearchTerm);
          console.log(filteredList);
          // Abort if search term has changed during fetch
          if (this._memberFilter !== scheduledSearchTerm) return;
          this.users = filteredList;
        }, 200);
      },
      change({ currentTarget }) {
        // Cast listbox to <select>
        const memberListbox = /** @type {HTMLSelectElement} */ (currentTarget);
        // Aborted if no selection
        if (memberListbox.selectedIndex === -1) return;
        const currentValue = memberListbox.value;
        // Has valid input, read value as number and set as id
        const numberValue = Number.parseInt(currentValue, 10);
        // Abort if NaN
        if (Number.isNaN(numberValue)) return;
        this._currentSelectedId = numberValue;
      },
    },
    searchInput: {
      input(event) {
        // Cast element to <input>
        const searchInput = /** @type {HTMLInputElement} */ (event.currentTarget);
        // get Element "value"
        const currentValue = searchInput.value;
        // Reset error state
        searchInput.setCustomValidity('');

        // Cast listbox to <select>
        const searchListbox = /** @type {HTMLSelectElement} */ (this.refs.searchListbox);
        // If listbox has a selection, use value from listbox ()
        if (searchListbox.selectedIndex !== -1) {
          // Has valid input, read value as number and set as eventId
          const numberValue = Number.parseInt(currentValue, 10);
          if (Number.isNaN(numberValue) === false) {
            this._currentSelectedId = numberValue;
            return;
          }
        }
        // Perform search instead

        // No event selected
        this._currentSelectedId = null;
        // Use current input as filter for search
        this._memberFilter = currentValue.trim().toLowerCase();

        // Store search term
        const scheduledSearchTerm = this._memberFilter;

        // Clear any existing debouncer
        clearTimeout(this._memberInputDebouncer);

        // Schedule search with 200ms debouncer
        this._memberInputDebouncer = setTimeout(async () => {
          // Abort if search term has changed during debouce (user input)
          if (this._memberFilter !== scheduledSearchTerm) return;
          const filteredList = await queryDelegates(scheduledSearchTerm);
          console.log(filteredList);
          // Abort if search term has changed during fetch
          if (this._memberFilter !== scheduledSearchTerm) return;
          this.users = filteredList;
        }, 200);
      },
      change({ currentTarget }) {
        // Cast listbox to <select>
        const searchListbox = /** @type {HTMLSelectElement} */ (currentTarget);
        // Aborted if no selection
        if (searchListbox.selectedIndex === -1) return;
        const currentValue = searchListbox.value;
        // Has valid input, read value as number and set as id
        const numberValue = Number.parseInt(currentValue, 10);
        // Abort if NaN
        if (Number.isNaN(numberValue)) {
          console.log('NULL');
          this._currentSelectedId = null;
          this.refreshMembers();
        } else {
          this._currentSelectedId = numberValue;
          this.filterMembers();
        }
      },
    },
  })
  .on({
    uriChanged(oldValue, newValue) {
      this.refreshGroup().catch(() => {});
    },
    connected() {
      console.log('connected group details');
    },
    infoEditableChanged(oldValue, isEditable) {
      if (isEditable) {
        const form = /** @type {HTMLFormElement} */(this.refs.infoForm);
        const input = /** @type {HTMLInputElement} */ (form.elements.namedItem('delegateGroupId'));
        input?.focus();
        input?.select();
      } else if (this.refs.infoResetButton.matches(':focus')) {
        this.refs.infoEditButton.focus();
      }
    },
  })
  .autoRegister('groups-detail');
