import { setLoginState } from './auth.js';

/**
 *
 * @param {string|URL|Request} input
 * @param {RequestInit} init
 */
export async function apiFetch(input, init = {}) {
  init.credentials ||= 'include';
  init.mode ||= 'cors';
  const response = await fetch(input, init);
  if (response.status === 401) {
    console.warn('401');
    setLoginState('unauthorized');
  }
  return response;
}
