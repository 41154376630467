import { queryGroupTypes } from '../../delegate.js';

/**
 * @param {typeof import('@shortfuse/materialdesignweb').CustomElement} Base
 */
export default function FilteredGroupTypesMixin(Base) {
  return Base
    .observe({
      _groupTypes: {
        /** @type {string[]} */
        value: [],
      },
      _groupTypeFilter: 'string',
    })
    .expressions({
      isNewGroupType({ _groupTypeFilter, _groupTypes }) {
        if (!_groupTypes || !_groupTypeFilter) return false;
        return !_groupTypes.includes(_groupTypeFilter);
      },
    })
    .methods({
      async refreshGroupTypes() {
        const { _groupTypeFilter } = this;
        this._groupTypes = await queryGroupTypes(_groupTypeFilter ? `%${_groupTypeFilter}%` : null);
      },
    })
    .on({
      async _groupTypeFilterChanged(oldValue, newValue) {
        await this.refreshGroupTypes();
      },
    });
}
