import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/IconButton.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import '@shortfuse/materialdesignweb/components/DialogActions.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/TextArea.js';
import '@shortfuse/materialdesignweb/components/SegmentedButtonGroup.js';
import '@shortfuse/materialdesignweb/components/SegmentedButton.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/List.js';
import '@shortfuse/materialdesignweb/components/Listbox.js';
import '@shortfuse/materialdesignweb/components/ListOption.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import '@shortfuse/materialdesignweb/components/Menu.js';
import '@shortfuse/materialdesignweb/components/MenuItem.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { csvFromJSON } from '../../../../utils/csv.js';
import { parseNullableNumber } from '../../../../utils/parse.js';
import { queryGroups } from '../../delegate.js';
import { createJWEventTemplate, deleteJWEventTemplate, deleteJWEventTicketRange, fetchJWEvent, fetchJWEventTemplate, getTemplateSampleEmailURL, getTemplateSampleTicketURL, putJWEventTicketRange, queryJWEventDelegates, queryJWEventTemplates, queryJWEventTicketRanges, sendJWEventTemplateEmailTickets, updateJWEvent, updateJWEventTemplate } from '../../jwevent.js';
import { createScanCheckpoint, queryScanCheckpoints, queryScans } from '../../scan.js';
import { queryTicketRanges } from '../../tickets.js';

export default CustomElement
  .extend()
  .observe({
    uri: 'string',
    _eventId: 'integer',
    infoEditable: 'boolean',
    eventTicketRanges: {
      type: 'object',
      reflect: false,
      /** @type {JWEventTicketRange[]} */
      value: null,
    },
    ticketRanges: {
      type: 'object',
      reflect: false,
      /** @type {TicketRange[]} */
      value: null,
    },
    groups: {
      type: 'object',
      reflect: false,
      /** @type {DelegateGroup[]} */
      value: null,
    },
    checkpoints: {
      type: 'object',
      reflect: false,
      /** @type {ScanCheckpoint[]} */
      value: null,
    },
    _scans: {
      /** @type {Scan[]} */
      value: [],
    },
    _filteredScans: {
      /** @type {Scan[]} */
      value: [],
    },
    _unscannedDelegates: {
      /** @type {JWEventDelegate[]} */
      value: [],
    },
    _templates: {
      /** @type {JWEventTemplate[]} */
      value: [],
    },
    /** String used to filter ticket range listbox */
    _ticketRangeFilter: 'string',
    _currentAssignTicketRangeId: 'integer',
    _currentRemoveTicketRangeId: 'integer',
    _currentRemoveTicketRangeName: 'string',
    _currentRemoveCheckpointId: 'integer',
    _currentRemoveCheckpointName: 'string',
    totalTicketCount: 'integer',
    _showScanFilters: 'boolean',
    _dialogTemplate: {
      type: 'object',
      /** @type {JWEventTemplate} */
      value: null,
    },
  })
  .set({
    /** @type {number} */
    lastFetchedId: null,
    /** @type {AbortController} */
    fetchAbortController: null,
    /** @type {JWEvent} */
    activity: null,
    /** @type {any} */
    _ticketRangeInputDebouncer: null,
    /** @type {string} */
    _scanDownloadURL: null,
    /** @type {number} */
    _currentTemplateListItemId: null,
  })
  .expressions({
    canEditInfo() {
      // Check user permisions
      return true;
    },
    infoEditButtonText({ infoEditable }) {
      return infoEditable ? 'Discard' : 'Edit';
    },
    startTimestampValue({ activity }) {
      if (!activity?.startTimestamp) return '';
      const d = new Date(activity.startTimestamp);
      return d.toISOString().slice(0, 16);
    },
    endTimestampValue({ activity }) {
      if (!activity?.endTimestamp) return '';
      const d = new Date(activity.endTimestamp);
      return d.toISOString().slice(0, 16);
    },
    eventTicketRangeName(data, { ticketRange }) {
      if (!data || !ticketRange) return null;
      return `${ticketRange.ticketRangeStart}-${ticketRange.ticketRangeEnd}`;
    },
    describeScan(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Unrecognized Ticket';
      if (scanObject.allowed || scanObject.ticketRanges?.length) return `${scanObject?.delegateLabel || 'Ticket'} - ${scanObject?.ticketId}`;
      return 'Unrecognized Ticket';
    },
    describeScan2(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return '';
      return (`ScannerId: ${scan?.scannerId} | ${scanObject?.eventName} (${scanObject?.checkpointName})`);
    },
    describeScan3(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Scan Error';
      if (scanObject.allowed) return 'Valid Ticket';
      if (scanObject?.ticketRanges?.length) return `No Entry - Range: ${scanObject?.ticketRanges[0].name}`;
      return 'Ticket not found in system';
    },
    computeUnscannedDelegate(data, { delegate }) {
      if (!delegate) return null;
      return delegate.label || (`Guest ${delegate.delegateId}`);
    },
    computeTemplateSupporting(data, { template }) {
      const item = /** @type {JWEventTemplate} */ (template);
      if (!item?.delegateGroup?.delegateGroupId) return null;
      return `${item.delegateGroup.type}: ${item.delegateGroup.name}`;
    },
    computeGroupLabel(data, { group }) {
      const item = /** @type {DelegateGroup} */ (group);
      if (!item) return '';
      return `${item.type} - ${item.name}`;
    },
    /* Update scan file timestamp on refresh */
    scansDownloadDownload({ activity, _scans }) {
      if (!_scans?.length) return null;
      if (!activity) return null;
      return `scans-${activity.name}-${new Date().toISOString()}.csv`;
    },
    computedHref(data, { scan }) {
      if (!scan) return '';
      return `/delegates/${scan.delegateId}`;
    },
    computedHref2(data, { delegate }) {
      if (!delegate) return '';
      return `/delegates/${delegate.delegateId}`;
    },
  })
  .html`
    <mdw-pane padding=pane id=pane flex-1>
      <mdw-box mdw-if={!uri} flex-1 x=center y=center>
        <mdw-headline padding-y=16><mdw-icon icon=event_note>Event</mdw-icon>No event selected.</mdw-headline>
      </mdw-box>
      <mdw-top-app-bar mdw-if={!!uri}>
        <mdw-icon-button class="toolbar-icon-button" slot="leading" icon="arrow_back" tabindex="0" on-click="{onBack}">Back</mdw-icon-button>
        <span><mdw-inline ink=primary>{activity.name}<mdw-inline></span>
      </mdw-top-app-bar>
      <mdw-box mdw-if={!!uri} flex-1 padding-y=16 gap=16>
        <mdw-card id=info-card elevated={!infoEditable} outlined={infoEditable} gap=16 padding=16>
          <form id=info-form>
            <mdw-box row x=between y=start>
              <mdw-headline>Info</mdw-headline>
              <mdw-box mdw-if={canEditInfo} row x=between y=start ink=primary gap=8 >
                <mdw-button id=info-edit-button  type=button filled=tonal mdw-if={!infoEditable}  >Edit</mdw-button>
                <mdw-button id=info-reset-button type=reset  filled=tonal mdw-if={infoEditable}   >Discard</mdw-button>
                <mdw-button id=info-save-button  type=submit filled       disabled={!infoEditable}>Save</mdw-button>
              </mdw-box>
            </mdw-box>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=name value={activity.name}                 id=info-name  label="Event Name"></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=startTimestamp value={startTimestampValue} id=info-start label="Start Time" type=datetime-local ></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=endTimestamp   value={endTimestampValue}   id=info-end   label="End Time"   type=datetime-local ></mdw-input>
          </form>
        </mdw-card>
        <mdw-card id=ticket-ranges-card elevated>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Ticket Ranges</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=ticket-ranges-add-button icon=add_circle>Add</mdw-icon-button>
              <mdw-icon-button id=ticket-ranges-refresh-button icon=refresh>Refresh</mdw-icon-button>              
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-list>
            <mdw-list-item mdw-for="{ticketRange of eventTicketRanges}">
              <span slot>{ticketRange.ticketRangeName}</span>
              <mdw-inline slot="supporting" ink="primary">{eventTicketRangeName}</mdw-inline>
              <mdw-box slot=trailing row gap=8>
              <mdw-icon-button icon=cancel data-ticket-range-id={ticketRange.ticketRangeId} data-ticket-range-name={ticketRange.ticketRangeName} on-click={onRemoveTicketRangeClick} >Remove</mdw-icon-button>
              </mdw-box>
            </mdw-list-item>
          </mdw-list>
          <mdw-divider></mdw-divider>
          <mdw-box row x=end gap=16 padding=24>
            <div>Total: <mdw-body inline ink=primary>{totalTicketCount}</mdw-body></div>
          </mdw-box>
        </mdw-card>
        <mdw-card id=checkpoints-card elevated>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Checkpoints</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=checkpoints-add-button icon=add_circle>Add</mdw-icon-button>
              <mdw-icon-button id=checkpoints-refresh-button icon=refresh>Refresh</mdw-icon-button>
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box mdw-if={!checkpoints.length} x=center y=center padding=24>
            No checkpoints.
          </mdw-box>
          <mdw-list mdw-if={!!checkpoints.length}>
            <mdw-list-item mdw-for="{checkpoint of checkpoints}">
              <span slot>{checkpoint.name}</span>
              <mdw-box slot=trailing row gap=8>
                <mdw-icon-button icon=barcode_scanner data-checkpoint-id={checkpoint.checkpointId} data-checkpoint-name={checkpoint.name} on-click={onScanCheckpointClick}>Scan</mdw-icon-button>
                <mdw-icon-button icon=cancel data-checkpoint-id={checkpoint.checkpointId} data-checkpoint-name={checkpoint.name} on-click={onRemoveCheckpointClick}>Remove</mdw-icon-button>
              </mdw-box>
            </mdw-list-item>
          </mdw-list>
        </mdw-card>
        <mdw-card id=scans-card elevated gap=0 padding=0>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Scans</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=scans-download-button icon=download href=# download={scansDownloadDownload} disabled={!_scans.length} >Download</mdw-icon-button>
              <mdw-icon-button id=scans-filter-button  icon=filter_alt type=checkbox>Filters</mdw-icon-button>
              <mdw-icon-button id=scans-refresh-button  icon=refresh>Refresh</mdw-icon-button>
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box id=scans-filter-box padding=16 gap=16 hidden={!_showScanFilters}>
            <mdw-title>Checkpoints</mdw-title>
            <mdw-box wrap row id=scans-filter-checkpoints gap=16 on-change="{refreshFilteredScans}">
              <form id=scans-filter-checkpoint-form>
                <mdw-filter-chip mdw-for="{checkpoint of checkpoints}" name={checkpoint.checkpointId} outlined>{checkpoint.name}</mdw-filter-chip>
              </form>
            </mdw-box>
            <mdw-title>Groups</mdw-title>
            <mdw-box wrap row id=scans-filter-group gap=16 on-change="{refreshFilteredScans}">
              <form id=scans-filter-group-form>
                <mdw-filter-chip mdw-for="{group of groups}" name={group.delegateGroupId} outlined>{group.name}</mdw-filter-chip>
              </form>
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box id=scan-tab-box>
            <mdw-tab-list sticky-parent tab-content-id="scans-tabcontent">
              <mdw-tab href="#scans-history">History</mdw-tab>
              <mdw-tab href="#scans-unscanned">Unscanned</mdw-tab>
            </mdw-tab-list>
            <mdw-tab-content id="scans-tabcontent">
              <mdw-tab-panel flex=column x=stretch color=surface-container id=scans-history>
                <mdw-box mdw-if={!_filteredScans.length} x=center y=center padding=24>
                  No results.
                </mdw-box>
                <mdw-list mdw-if={!!_filteredScans.length}>
                  <mdw-list-item mdw-for="{scan of _filteredScans}" class=scan-list-item href={computedHref}>
                    {describeScan}
                    <mdw-inline slot="trailing" ink="primary">${(data, { scan }) => (scan ? new Date(scan.timestamp).toLocaleString() : '')} </mdw-inline>
                    <mdw-inline slot="supporting">
                      <mdw-body ink=secondary size=medium>{describeScan2}</mdw-body>
                      <mdw-body ink=tertiary size=small>{describeScan3}</mdw-body>
                    </mdw-inline>
                  </mdw-list-item>
                </mdw-list>
              </mdw-tab-panel>
              <mdw-tab-panel flex=column x=stretch color=secondary-container id=scans-unscanned>
                <mdw-box mdw-if={!_unscannedDelegates.length} x=center y=center padding=24>
                  No unscanned.
                </mdw-box>
                <mdw-list mdw-if={!!_unscannedDelegates.length}>
                  <mdw-list-item mdw-for="{delegate of _unscannedDelegates}" href={computedHref2}> 
                    {computeUnscannedDelegate}
                  </mdw-list-item>
                </mdw-list>
              </mdw-tab-panel>
            </mdw-tab-content>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box row x=end gap=16 padding=24>
            <div>Unscanned: <mdw-body inline ink=primary>{_unscannedDelegates.length}</mdw-body></div>
            <div>Results: <mdw-body inline ink=primary>{_filteredScans.length}</mdw-body></div>
            <div>Total: <mdw-body inline ink=primary>{_scans.length}</mdw-body></div>
          </mdw-box>
        </mdw-card>
        <mdw-card id=templates-card elevated>
          <mdw-box row x=between y=start padding=16>
            <mdw-headline>Templates</mdw-headline>
            <mdw-box row x=between ink=primary gap=8>
              <mdw-icon-button id=templates-add-button icon=add_circle>Add</mdw-icon-button>
              <mdw-icon-button id=templates-refresh-button icon=refresh>Refresh</mdw-icon-button>
            </mdw-box>
          </mdw-box>
          <mdw-divider></mdw-divider>
          <mdw-box mdw-if={!_templates.length} x=center y=center padding=24>
            No templates.
          </mdw-box>
          <mdw-list mdw-if={!!_templates.length}>
            <mdw-list-item mdw-for="{template of _templates}" supporting={computeTemplateSupporting}>
              <span slot>{template.name}</span>
              <mdw-icon-button slot=trailing icon=more_vert data-id={template.templateId} data-name={template.name} on-click={onTemplateListItemMoreClick}>Menu</mdw-icon-button>
            </mdw-list-item>
          </mdw-list>
        </mdw-card>
      </mdw-box>
    </mdw-pane>
    <mdw-menu id=templates-list-item-menu>
      <mdw-menu-item data-action=edit icon=edit>Edit</mdw-menu-item>
      <mdw-menu-item data-action=email icon=email>Email</mdw-menu-item>
      <mdw-menu-item data-action=sample-email icon=image>Sample Email</mdw-menu-item>
      <mdw-menu-item data-action=sample-ticket icon=badge>Sample Ticket</mdw-menu-item>
      <mdw-menu-item data-action=remove icon=cancel>Remove</mdw-menu-item>
    </mdw-menu>
    <mdw-dialog id=dialog-assign-ticket-range icon=add_circle headline="Assign Ticket Range" default="confirm">
      <form id=dialog-assign-ticket-range-form method=dialog>
        <mdw-box padding-y=8>
          <mdw-input id=dialog-assign-ticket-range-input label="Ticket Range" outlined autocomplete-inline autocomplete-list autosuggest-inline required>
            <mdw-listbox id=dialog-assign-ticket-range-listbox>
              <mdw-list-option mdw-for="{ticketRange of ticketRanges}" value={ticketRange.ticketRangeId}>{ticketRange.name}</mdw-list-option>
            </mdw-listbox>
          </mdw-input>
        </mdw-box>
      </form>
      <div slot=actions>
          <input form=dialog-assign-ticket-range-form type=submit hidden value=confirm tabindex=-1 aria-hidden=true>
          <!-- Forms submit nearest type=submit control on ENTER key -->
          <mdw-button form=dialog-assign-ticket-range-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=dialog-assign-ticket-range-form type=submit value=confirm>Assign</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-remove-ticket-range icon=cancel headline="Remove Ticket Range" confirm="Remove" cancel="Close" default="confirm">
      Remove:&nbsp;<mdw-body inline large ink=error>{_currentRemoveTicketRangeName}</mdw-body>?
    </mdw-dialog>
    <mdw-dialog id=dialog-add-checkpoint icon=add_circle headline="Add Checkpoint" default="confirm">
      <form id=dialog-add-checkpoint-form method=dialog>
        <mdw-box padding-y=8>
          <mdw-input id=dialog-add-checkpoint-name name=name label="Name" required outlined></mdw-input>
        </mdw-box>
      </form>
      <div slot=actions>
        <input form=dialog-add-checkpoint-form type="submit" hidden value=confirm tabindex=-1 aria-hidden=true >
        <mdw-button form=dialog-add-checkpoint-form type=submit value=cancel formnovalidate>Close</mdw-button>
        <mdw-button form=dialog-add-checkpoint-form type=submit value=confirm>Add</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-remove-checkpoint icon=cancel headline="Remove Checkpoint" confirm="Remove" cancel="Close" default="confirm">
      Remove:&nbsp;<mdw-body inline large ink=error>{_currentRemoveCheckpointName}</mdw-body>?
    </mdw-dialog>
    <mdw-dialog id=dialog-add-template icon=add_circle headline="Add Template" default="confirm" dividers>
      <form id=dialog-add-template-form method=dialog>
        <mdw-box padding=24 gap=16>
          <mdw-input name=name label="Name" required outlined></mdw-input>
          <mdw-input name=email_subject label="Email Subject" required outlined></mdw-input>
          <mdw-textarea name=email_body label="Email Body" placeholder="<html ..." required outlined minrows=4 maxrows=4 supporting="HTML Format"></mdw-textarea>
          <mdw-input type=select-one name=delegate_group_name label="Group" outlined autocomplete-inline autocomplete-list autosuggest-inline value="">
            <mdw-listbox>
              <mdw-list-option value="">None</mdw-list-option>
              <mdw-list-option mdw-for="{group of groups}" supporting="{group.name}" value={group.delegateGroupId} label={computeGroupLabel}>{group.type}</mdw-list-option>
            </mdw-listbox>
          </mdw-input>
          <mdw-box row flex gap=8>
            <mdw-input name=qr_x    label="QR X"    placeholder="Center" outlined type=number step=1 min=0 max=960 input-suffix=px supporting="X position of QR image"></mdw-input>
            <mdw-input name=qr_y    label="QR Y"    placeholder="Center" outlined type=number step=1 min=0 max=960 input-suffix=px supporting="Y position of QR image"></mdw-input>
            <mdw-input name=qr_size label="QR Size" placeholder="50%"    outlined type=number step=1 min=0 max=960 input-suffix=px supporting="Size of QR image"></mdw-input>
          </mdw-box>
          <mdw-textarea name=image label="Image Data" placeholder="<svg ..." minrows=4 maxrows=4 outlined required supporting="Image data in <svg>"></mdw-textarea>
        </mdw-box>
      </form>
      <div slot=actions>
          <input form=dialog-add-template-form type="submit" hidden value=confirm tabindex=-1 aria-hidden="true" >
          <mdw-button form=dialog-add-template-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=dialog-add-template-form type=submit value=confirm>Add</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-edit-template icon=edit headline="Edit Template" default="confirm">
      <form id=dialog-edit-template-form method=dialog>
        <mdw-box padding=24 gap=16>
          <mdw-input name=name label="Name" required outlined value={_dialogTemplate.name}></mdw-input>
          <mdw-input name=email_subject label="Email Subject" required outlined value={_dialogTemplate.email.subject}></mdw-input>
          <mdw-textarea name=email_body label="Email Body" placeholder="<html ..." required outlined minrows=4 maxrows=4 supporting="HTML Format" value={_dialogTemplate.email.body}></mdw-textarea>
          <mdw-input type=select-one name=delegate_group_name label="Group" outlined autocomplete-inline autocomplete-list autosuggest-inline value={_dialogTemplate.delegateGroup.delegateGroupId}>
            <mdw-listbox>
              <mdw-list-option value="">None</mdw-list-option>
              <mdw-list-option mdw-for="{group of groups}" supporting="{group.name}" value={group.delegateGroupId} label={computeGroupLabel}>{group.type}</mdw-list-option>
            </mdw-listbox>
          </mdw-input>
          <mdw-box row flex gap=8>
            <mdw-input name=qr_x    value={_dialogTemplate.qr.x}    label="QR X"    placeholder="Center" outlined type=number step=1 min=0 max=960 input-suffix=px supporting="X position of QR image"></mdw-input>
            <mdw-input name=qr_y    value={_dialogTemplate.qr.y}    label="QR Y"    placeholder="Center" outlined type=number step=1 min=0 max=960 input-suffix=px supporting="Y position of QR image"></mdw-input>
            <mdw-input name=qr_size value={_dialogTemplate.qr.size} label="QR Size" placeholder="50%"    outlined type=number step=1 min=0 max=960 input-suffix=px supporting="Size of QR image"></mdw-input>
          </mdw-box>
          <mdw-textarea name=image label="Image Data" placeholder="<svg ..." minrows=4 maxrows=4 outlined required supporting="Image data in <svg>" value={_dialogTemplate.image}></mdw-textarea>
        </mdw-box>
      </form>
      <div slot=actions>
          <input      form=dialog-edit-template-form type=submit value=confirm tabindex=-1 aria-hidden=true hidden >
          <mdw-button form=dialog-edit-template-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=dialog-edit-template-form type=submit value=confirm>Update</mdw-button>
      </div>
      </form>
    </mdw-dialog>
    <mdw-dialog id=dialog-email-template icon=mail headline="Email Tickets" confirm="Email" cancel="Cancel" default="cancel">
      Send batch emails with template&nbsp;<mdw-body inline large ink=primary>{_dialogTemplate.name}</mdw-body>?
    </mdw-dialog>
    <mdw-dialog id=dialog-remove-template icon=mail headline="Remove Template" confirm="Remove" cancel="Cancel" default="cancel">
      <div>Remove template&nbsp;<mdw-body inline large ink=error>{_dialogTemplate.name}</mdw-body>?</div>
    </mdw-dialog>
  `
  .css`
    :host {
      flex: 1;
    }

    form {
      display: contents;
    }

    #pane {
      display: flex;
      flex-direction: column;
    }

    #scan-tab-box {
      min-block-size: 128px;
      max-block-size: 50vh;
      max-block-size: 50dvh;

      z-index: -1;
    }

    #scans-filter-box[hidden] {
      /** TODO: Animate */
      display: none;
    }

    #dialog-add-template-content {
      overflow-y: auto;

      inline-size: 50vw;

      min-inline-size: 100%;
    }
  `
  .methods({
    onBack() { window.history.back(); },
    async refreshEventCheckpoints() {
      this.checkpoints = await queryScanCheckpoints({ eventId: this._eventId });
    },
    async refreshTemplates() {
      this._templates = await queryJWEventTemplates(this._eventId);
    },
    async refreshEventTicketRanges() {
      this.eventTicketRanges = await queryJWEventTicketRanges({ eventId: this._eventId });
      this.totalTicketCount = this.eventTicketRanges
        .reduce((sum, ticketRange) => sum + (ticketRange.ticketRangeEnd - ticketRange.ticketRangeStart) + 1, 0);
    },
    async refreshScans() {
      this._scans = await queryScans({ eventId: this._eventId });
    },
    async refreshUnscannedDelegates() {
      this._unscannedDelegates = await queryJWEventDelegates(this._eventId, { scanTimestamp: null });
    },
    refreshFilteredScans() {
      const groupFormData = new FormData(this.refs.scansFilterGroupForm);
      const setOfCheckedGroups = new Set(
        [
          ...groupFormData.entries()]
          .filter(([key, value]) => value === 'on')
          .map(([key, value]) => key),
      );
      const checkpointFormData = new FormData(this.refs.scansFilterCheckpointForm);
      const setOfCheckedCheckpoints = new Set(
        [
          ...checkpointFormData.entries()]
          .filter(([key, value]) => value === 'on')
          .map(([key, value]) => key),
      );
      this._filteredScans = setOfCheckedGroups.size === 0 && setOfCheckedCheckpoints.size === 0
        ? this._scans
        : this._scans
          .filter(({ checkpointId, delegateGroups }) => {
            if (setOfCheckedCheckpoints.size && !setOfCheckedCheckpoints.has(checkpointId.toString())) return false;
            if (setOfCheckedGroups.size && !delegateGroups
              .some(({ delegateGroupId }) => setOfCheckedGroups.has(delegateGroupId.toString()))) return false;
            return true;
          });
    },
    async refreshEvent() {
      if (!this.uri) return;
      try {
        // Convert URI to number
        this._eventId = Number.parseInt(this.uri, 10);
        // Fetch record from Server using jwevent service
        const [activity, groups] = await Promise.all([
          fetchJWEvent(this._eventId),
          queryGroups(),
          this.refreshScans(),
          this.refreshUnscannedDelegates(),
          this.refreshEventTicketRanges(),
          this.refreshEventCheckpoints(),
          this.refreshTemplates(),
        ]);

        // Attach object to current element state
        this.activity = activity;
        this.groups = groups;

        this.refreshFilteredScans();
        // Update all HTML
        this.render({ activity: this.activity });

        // Reset forms
        this.refs.infoForm.reset();
      } catch (e) {
        console.error(e);
      }
    },
    /** @param {PointerEvent} event */
    onRemoveTicketRangeClick(event) {
      /** @type {HTMLButtonElement} */
      const element = event.currentTarget;
      console.log('_currentRemoveRangeId:', element.dataset.ticketRangeId);
      this._currentRemoveTicketRangeId = Number.parseInt(element.dataset.ticketRangeId, 10);
      this._currentRemoveTicketRangeName = element.dataset.ticketRangeName;
      this.refs.dialogRemoveTicketRange.showModal();
    },
    /** @param {PointerEvent} event */
    onRemoveCheckpointClick(event) {
      /** @type {HTMLButtonElement} */
      const element = event.currentTarget;
      console.log('_currentRemoveRangeId:', element.dataset.ticketRangeId);
      this._currentRemoveCheckpointId = Number.parseInt(element.dataset.checkpointId, 10);
      this._currentRemoveCheckpointName = element.dataset.checkpointName;
      this.refs.dialogRemoveCheckpoint.showModal();
    },
    onScanCheckpointClick(event) {
      console.log('Not implemented.');
    },
    onTemplateListItemMoreClick(event) {
      const id = Number.parseInt(event.currentTarget.dataset.id, 10);
      this._currentTemplateListItemId = id;
      this.refs.templatesListItemMenu.show(event.currentTarget);
    },
  })
  .childEvents({
    infoForm: {
      async submit(event) {
        event.preventDefault();
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        const formData = new FormData(form);
        /** @type {Partial<JWEvent>} */
        const updateInfo = Object.fromEntries(formData.entries());
        // Manually convert Date to number
        // Server should parse in UTC, but better to be explicit
        updateInfo.startTimestamp = form.elements.startTimestamp.valueAsNumber;
        updateInfo.endTimestamp = form.elements.endTimestamp.valueAsNumber;
        await updateJWEvent(this._eventId, updateInfo);
        await this.refreshEvent();
        this.infoEditable = false;
      },
      reset() {
        this.infoEditable = false;
      },
      keydown(event) {
        if (event.key === 'Escape') {
          if (!this.infoEditable) return;
          event.preventDefault();
          const form = /** @type {HTMLFormElement} */ (event.currentTarget);
          form.reset();
        }
      },
    },
    infoEditButton: {
      click() {
        this.infoEditable = true;
      },
    },
    ticketRangesRefreshButton: {
      async click() {
        await this.refreshEventTicketRanges();
      },
    },
    ticketRangesAddButton: {
      async click() {
        this.refs.dialogAssignTicketRangeForm.reset();
        this._currentAssignTicketRangeId = null;
        this.ticketRanges = await queryTicketRanges();
        this.refs.dialogAssignTicketRange.showModal();
      },
    },
    templatesListItemMenu: {
      async click({ target, currentTarget }) {
        this._dialogTemplate = await fetchJWEventTemplate(this._eventId, this._currentTemplateListItemId);
        switch (target.dataset.action) {
          case 'edit':
            this.refs.dialogEditTemplateForm.reset();
            this.refs.dialogEditTemplateForm.elements.delegate_group_name.value = this._dialogTemplate.delegateGroup?.delegateGroupId ?? '';
            this.refs.dialogEditTemplate.showModal();
            break;
          case 'email':
            this.refs.dialogEmailTemplate.showModal();
            break;
          case 'sample-email':
            window.open(getTemplateSampleEmailURL({
              templateId: this._currentTemplateListItemId,
              eventId: this._eventId,
            }), '_blank').focus();
            break;
          case 'sample-ticket':
            window.open(getTemplateSampleTicketURL({
              templateId: this._currentTemplateListItemId,
              eventId: this._eventId,
            }), '_blank').focus();
            break;
          case 'remove':
            this.refs.dialogRemoveTemplate.showModal();
            break;
          default:
        }
        currentTarget.close();
      },
    },
    scansFilterButton: {
      change({ currentTarget }) {
        this._showScanFilters = currentTarget.checked;
      },
    },
    scansRefreshButton: {
      async click() {
        await Promise.all([
          this.refreshUnscannedDelegates(),
          this.refreshScans(),
        ]);
        await this.refreshFilteredScans();
      },
    },
    scansDownloadButton: {
      'mdw:hyperlink'(event) {
        if (event.currentTarget.disabled) {
          event.currentTarget.href = '#';
          event.preventDefault();
          event.stopImmediatePropagation();
          return;
        }
        const flattened = this._scans.map((scan) => ({
          timestamp: new Date(scan.timestamp),
          ticketId: scan.ticketId,
          checkpointId: scan.checkpointId,
          scannerId: scan.scannerId,
          delegateId: scan.delegateId,
          allowed: scan.allowed,
          checkpointName: scan.checkpointName,
          eventId: scan.eventId,
          eventName: scan.eventName,
          delegateLabel: scan.delegateLabel,
          delegateGroups: scan.delegateGroups?.length
            ? Object.fromEntries(scan.delegateGroups.map(((group) => [group.type.toLowerCase(), group.name])))
            : null,
          ticketRanges: scan.ticketRanges?.length
            ? Object.fromEntries(scan.ticketRanges.map((ticketRange) => [ticketRange.name.toLowerCase(), true]))
            : null,
        }));
        const csv = csvFromJSON(flattened, { dateLocal: true });
        if (this._scanDownloadURL) {
          URL.revokeObjectURL(this._scanDownloadURL);
        }
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        this._scanDownloadURL = URL.createObjectURL(blob);
        // Replace HREF with new URL
        event.currentTarget.href = this._scanDownloadURL;
      },
    },
    checkpointsAddButton: {
      click() {
        this.refs.dialogAddCheckpointForm.reset();
        this.refs.dialogAddCheckpoint.showModal();
      },
    },
    checkpointsRefreshButton: {
      async click() {
        await this.refreshEventCheckpoints();
      },
    },
    templatesAddButton: {
      click() {
        this.refs.dialogAddTemplateForm.reset();
        this.refs.dialogAddTemplate.showModal();
      },
    },
    templatesRefreshButton: {
      async click() {
        await this.refreshTemplates();
      },
    },
    dialogAssignTicketRange: {
      input({ target }) { return target.setCustomValidity(''); },
      change({ target }) { return target.setCustomValidity(''); },
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);

        if (!this._currentAssignTicketRangeId) {
          this.refs.dialogAssignTicketRangeInput.setCustomValidity('Please select a ticket range');
          this.refs.dialogAssignTicketRangeInput.reportValidity();
          return;
        }
        /** @type {JWEventTicketRange} */
        const newEventTicketRange = {
          eventId: this._eventId,
          ticketRangeId: this._currentAssignTicketRangeId || null,
        };

        console.log(newEventTicketRange);

        try {
          // TODO: track page stage
          await putJWEventTicketRange(newEventTicketRange);
          await this.refreshEventTicketRanges();
          dialog.close();
        } catch (e) {
          console.error(e);
        }
      },
    },
    dialogAddCheckpoint: {
      async submit(event) {
        // @ts-ignore Skip cast
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);

        const checkpointNameElement = /** @type {HTMLInputElement} */ (this.refs.dialogAddCheckpointName);
        const checkpointName = checkpointNameElement.value;

        try {
          // TODO: track page stage
          await createScanCheckpoint({
            name: checkpointName,
            eventId: this.activity.eventId,
          });
          await this.refreshEventCheckpoints();
          dialog.close();
        } catch (e) {
          // Abort if user left already (eg: timeout)
          console.error(e);
          // Name is already in use
          // TODO: Validate HTTP CODE 409 error
          this.refs.dialogAddCheckpointName.setCustomValidity('Name already in use.');
          this.refs.dialogAddCheckpointName.reportValidity();
        }
      },
    },
    dialogRemoveTicketRange: {
      async close(event) {
        /** @type {HTMLDialogElement} */
        const dialog = event.currentTarget;
        if (dialog.returnValue !== 'confirm') return;
        try {
          await deleteJWEventTicketRange({
            eventId: this._eventId,
            ticketRangeId: this._currentRemoveTicketRangeId,
          });
        } catch {}
        await this.refreshEventTicketRanges();
      },
    },
    dialogAddTemplate: {
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);
        form.disabled = true;
        try {
          await createJWEventTemplate({
            eventId: this._eventId,
            name: form.elements.name.value,
            email: {
              subject: form.elements.email_subject.value,
              body: form.elements.email_body.value,
            },
            qr: {
              x: parseNullableNumber(form.elements.qr_x.valueAsNumber),
              y: parseNullableNumber(form.elements.qr_y.valueAsNumber),
              size: parseNullableNumber(form.elements.qr_size.valueAsNumber),
            },
            delegateGroup: {
              delegateGroupId: parseNullableNumber(form.elements.delegate_group_name.value),
            },
            image: form.elements.image.value,
          });
          await this.refreshTemplates();
          dialog.close();
        } catch (e) {
          console.error(e);
          // TODO: Explaining failure (name isn't unique?)
        }
        form.disabled = false;
      },
    },
    dialogEditTemplate: {
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);
        form.disabled = true;
        try {
          await updateJWEventTemplate(
            this._eventId,
            this._dialogTemplate.templateId,
            {
              name: form.elements.name.value,
              email: {
                subject: form.elements.email_subject.value,
                body: form.elements.email_body.value,
              },
              qr: {
                x: parseNullableNumber(form.elements.qr_x.valueAsNumber),
                y: parseNullableNumber(form.elements.qr_y.valueAsNumber),
                size: parseNullableNumber(form.elements.qr_size.valueAsNumber),
              },
              delegateGroup: {
                delegateGroupId: parseNullableNumber(form.elements.delegate_group_name.value),
              },
              image: form.elements.image.value,
            },
          );
          await this.refreshTemplates();
          dialog.close();
        } catch (e) {
          console.error(e);
          // TODO: Explaining failure (name isn't unique?)
        }
        form.disabled = false;
      },
    },
    dialogRemoveTemplate: {
      async close(event) {
        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        if (dialog.returnValue !== 'confirm') return;
        try {
          await deleteJWEventTemplate({
            eventId: this._eventId,
            templateId: this._dialogTemplate.templateId,
          });
          await this.refreshTemplates();
        } catch {}
      },
    },
    dialogEmailTemplate: {
      async close(event) {
        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        if (dialog.returnValue !== 'confirm') return;
        try {
          await sendJWEventTemplateEmailTickets({
            eventId: this._eventId,
            templateId: this._dialogTemplate.templateId,
          });
        } catch {}
      },
    },
    dialogAssignTicketRangeInput: {
      input(event) {
        // Cast element to <input>
        const input = /** @type {HTMLInputElement} */ (event.currentTarget);
        // get Element "value"
        const currentValue = input.value;
        // Reset error state
        input.setCustomValidity('');

        // Cast listbox to <select>
        const ticketRangeListbox = /** @type {HTMLSelectElement} */ (this.refs.dialogAssignTicketRangeListbox);
        // If listbox has a selection, use value from listbox ()
        if (ticketRangeListbox.selectedIndex !== -1) {
          // Has valid input, read value as number and set as eventId
          const numberValue = Number.parseInt(currentValue, 10);
          if (Number.isNaN(numberValue) === false) {
            this._currentAssignTicketRangeId = numberValue;
            return;
          }
        }
        // Perform search instead

        // No event selected
        this._currentAssignTicketRangeId = null;
        // Use current input as filter for search
        this._ticketRangeFilter = currentValue.trim().toLowerCase();

        // Store search term
        const scheduledSearchTerm = this._ticketRangeFilter;

        // Clear any existing debouncer
        clearTimeout(this._ticketRangeInputDebouncer);

        // Schedule search with 200ms debouncer
        this._ticketRangeInputDebouncer = setTimeout(async () => {
          // Abort if search term has changed during debouce (user input)
          if (this._ticketRangeFilter !== scheduledSearchTerm) return;
          const filteredList = await queryTicketRanges(scheduledSearchTerm);
          // Abort if search term has changed during fetch
          if (this._ticketRangeFilter !== scheduledSearchTerm) return;
          this.ticketRanges = filteredList;
        }, 200);
      },
      change({ currentTarget }) {
        // Cast listbox to <select>
        const ticketRangeListbox = /** @type {HTMLSelectElement} */ (currentTarget);
        // Aborted if no selection
        if (ticketRangeListbox.selectedIndex === -1) return;
        const currentValue = ticketRangeListbox.value;
        // Has valid input, read value as number and set as id
        const numberValue = Number.parseInt(currentValue, 10);
        // Abort if NaN
        if (Number.isNaN(numberValue)) return;
        this._currentAssignTicketRangeId = numberValue;
      },
    },
  })
  .on({
    uriChanged(oldValue, newValue) {
      this.refreshEvent().catch(() => {});
    },
    connected() {
      console.log('connected event details');
    },
    infoEditableChanged(oldValue, isEditable) {
      if (isEditable) {
        const form = /** @type {HTMLFormElement} */(this.refs.infoForm);
        const input = /** @type {HTMLInputElement} */ (form.elements.namedItem('eventId'));
        input?.focus();
        input?.select();
      } else if (this.refs.infoResetButton.matches(':focus')) {
        this.refs.infoEditButton.focus();
      }
    },
    _showScanFiltersChanged(oldValue, newValue) {
      if (!newValue) {
        this.refs.scansFilterCheckpointForm.reset();
        this.refs.scansFilterGroupForm.reset();
        this.refreshFilteredScans();
      }
    },
    scansChanged(oldValue, newValue) {
      this.refs.scansDownloadButton.href = '#';
    },
  })
  .autoRegister('events-detail');
