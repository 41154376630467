import { apiFetch } from './api.js';
import { API_BASE_URL } from './constants.js';

/** @type {Map<number, TicketRange>} */
export const eventsCache = new Map();

const SERVER_API = new URL('ticketranges/', API_BASE_URL);

// /** @return {TicketRange[]} */
// export function refreshTicketRange() {
//   return [...eventsCache.values()];
// }

/**
 * @param {string} filter
 * @return {Promise<TicketRange[]>}
 */
export async function queryTicketRanges(filter = '') {
  const url = new URL(SERVER_API);
  if (filter) {
    url.searchParams.set('name', filter);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

export const refreshTicketRanges = queryTicketRanges;

/**
 * @param {number} ticketRangeId
 * @return {Promise<TicketRange|null>}
 */
export async function fetchTicketRange(ticketRangeId) {
  const url = new URL(ticketRangeId.toString(10), SERVER_API);
  const response = await apiFetch(url);
  if (response.ok) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} ticketRangeId
 * @return {Promise<boolean>} Returns true if deleted
 */
export async function deleteTicketRange(ticketRangeId) {
  const url = new URL(ticketRangeId.toString(10), SERVER_API);
  const response = await apiFetch(url, {
    method: 'DELETE',
  });

  if (response.status === 410) return true;

  return false;
}

/**
 * @param {number} ticketRangeId
 * @param {Partial<TicketRange>} patchInfo
 * @return {Promise<TicketRange>}
 */
export async function updateTicketRange(ticketRangeId, patchInfo) {
  const url = new URL(ticketRangeId.toString(10), SERVER_API);
  const response = await apiFetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
    body: JSON.stringify(patchInfo),
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<TicketRange>} addInfo
 * @return {Promise<TicketRange>}
 */
export async function createTicketRange(addInfo) {
  const url = new URL(SERVER_API);
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(addInfo),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
