import '@shortfuse/materialdesignweb/components/Pane.js';
import '@shortfuse/materialdesignweb/components/Progress.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

export default CustomElement
  .extend()
  .html`
    <mdw-page>
      <mdw-pane x=center y=center>
        <mdw-progress circle></mdw-progress>
      </mdw-pane>
    <mdw-page>
  `
  .css`
    mdw-page {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      block-size: 100%;
      padding: 24px;
    }
  `
  .autoRegister('splash-page');
