import '@shortfuse/materialdesignweb/components/Root.js';
import '@shortfuse/materialdesignweb/components/FabContainer.js';
import '@shortfuse/materialdesignweb/components/Fab.js';
import '@shortfuse/materialdesignweb/components/SnackbarContainer.js';
import '@shortfuse/materialdesignweb/components/Snackbar.js';
import '@shortfuse/materialdesignweb/components/NavDrawer.js';
import '@shortfuse/materialdesignweb/components/NavDrawerItem.js';
import '@shortfuse/materialdesignweb/components/NavRail.js';
import '@shortfuse/materialdesignweb/components/NavRailItem.js';
import '@shortfuse/materialdesignweb/components/NavBar.js';
import '@shortfuse/materialdesignweb/components/NavBarItem.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import './events/EventsPage.js';
import './delegates/DelegatesPage.js';
import './groups/GroupsPage.js';
import './scans/ScansPage.js';
import './pages/SplashPage.js';
import './pages/LoginPage.js';
import './tickets/TicketsPage.js';
import './profile/ProfilePage.js';

import { getLoginState, loginStateChangedEvent, profileUpdatedEvent } from '../auth.js';
import { navigate, pageChangeEvent } from '../page.js';

import { setFabContainer } from './fabManager.js';

// eslint-disable-next-line no-unused-expressions

export default CustomElement
  .extend()
  .observe({
    page: {
      reflect: false,
      value: '/splash',
    },
    loggedIn: {
      type: 'boolean',
      reflect: false,
    },
    profile: {
      type: 'object',
      reflect: false,
      /** @type {Delegate} */
      value: null,
    },
    links: {
      type: 'object',
      value: [{
        name: 'Guests',
        icon: 'badge',
        href: '/delegates',
      },
      {
        name: 'Groups',
        icon: 'groups',
        href: '/groups',
      },
      {
        name: 'Events',
        icon: 'event',
        href: '/jwevents',
      },
      {
        name: 'Tickets',
        icon: 'local_activity',
        href: '/tickets',
      },
      {
        name: 'Scans',
        icon: 'barcode_scanner',
        href: '/scans',
      },
      {
        name: 'Profile',
        icon: 'account_circle',
        href: '/profile',
      },
      // {
      //   name: 'Photos',
      //   icon: 'photo_library',
      //   href: '/photos',
      // },
      ],
      reflect: false,
    },
  })
  .methods({
    /**
     * @param {string} url
     * @return {boolean}
     */
    isPageAccessible(url) {
      const { profile } = this;
      if (!profile) return false;
      if (profile.roleSystemAdmin || profile.roleAdmin) return true;
      switch (url) {
        case '/delegates':
          return profile.roleTicketAgent;
        case '/groups':
        case '/events':
          return false;
        case '/tickets':
          return false;
        case '/scans':
          return profile.roleTicketAgent || profile.roleScanner;
        case '/profile':
          return true;
        default:
          return false;
      }
    },
  })
  .expressions({
    isCurrentLink({ page, links }, { link }) {
      return link?.href === page;
    },
    isLinkVisible({ profile }, { link }) {
      if (!profile) return false;
      return this.isPageAccessible(link.href);
    },
    computeAppBarTitle({ page }) {
      switch (page) {
        case '/login': return 'Login';
        case '/delegates': return 'Guests';
        case '/groups': return 'Groups';
        case '/jwevents': return 'Events';
        case '/tickets': return 'Tickets';
        case '/scans': return 'Scans';
        case '/photos': return 'Photos';
        case '/profile': return 'Profile';
        default:
      }
      return page;
    },
  })
  .html`
    <mdw-root id=root>
      <mdw-nav-drawer mdw-if={loggedIn} id="drawer" slot="start" on-toggle="{checkFabPosition}">
        <mdw-box id=fab-drawer padding-y=16></mdw-box>
        <mdw-nav-drawer-item mdw-for="{link of links}" icon="{link.icon}" href={link.href} active={isCurrentLink} hidden={!isLinkVisible}>{link.name}</mdw-nav-drawer-item>
      </mdw-nav-drawer>

      <mdw-nav-rail mdw-if={loggedIn} id="rail" slot="start" padding-y=16 on-toggle="{checkFabPosition}">
        <div id=fab-rail slot=start></div>
        <mdw-nav-rail-item mdw-for="{link of links}" icon="{link.icon}" href={link.href} active={isCurrentLink} hidden={!isLinkVisible}>{link.name}</mdw-nav-rail-item>
      </mdw-nav-rail>

      <mdw-nav-bar mdw-if={loggedIn} id=nav-bar slot=bottom>
        <mdw-nav-bar-item mdw-for="{link of links}" icon="{link.icon}" href={link.href} active={isCurrentLink} hidden={!isLinkVisible}>{link.name}</mdw-nav-bar-item>
      </mdw-nav-bar>

      <mdw-fab-container mdw-if={loggedIn} id=fab-container slot=bottom>
        <div id=fab-holder></div>
      </mdw-fab-container>

      <mdw-snackbar-container mdw-if={loggedIn} id=snackbar-container slot=bottom-fixed>
      </mdw-snackbar-container>

      <splash-page mdw-if="${({ page }) => page === '/splash'}"></splash-page>
      <login-page mdw-if="${({ page }) => page === '/login'}"></login-page>
      <delegates-page mdw-if="${({ page }) => page === '/delegates'}"></delegates-page>
      <groups-page mdw-if="${({ page }) => page === '/groups'}"></groups-page>
      <events-page mdw-if="${({ page }) => page === '/jwevents'}"></events-page>
      <tickets-page mdw-if="${({ page }) => page === '/tickets'}"></tickets-page>
      <scans-page mdw-if="${({ page }) => page === '/scans'}"></scans-page>
      <photos-page mdw-if="${({ page }) => page === '/photos'}"></photos-page>
      <profile-page mdw-if="${({ page }) => page === '/profile'}"></profile-page>

    </mdw-root>
  `
  .css`
    #root {
      display: grid;
    }

    .pane {
      block-size: 100%;
      max-inline-size: var(--mdw-page__max-width);
      margin-inline: auto;
      padding: var(--mdw-page__margin);
      padding-block-start: 0;
    }

    :is(mdw-nav-rail-item, mdw-nav-drawer-item, mdw-nav-bar-item)[hidden] {
      display: none;
    }

    #fab-drawer:empty {
      transition: padding 100ms;
      padding: 0;
    }
  `
  .methods({
    checkLoginState(state = getLoginState()) {
      console.log('checkLoginState', state);
      this.loggedIn = (state === 'loggedin');
      if (state === 'unauthorized') {
        navigate('/login', true);
      }
    },
    checkFabPosition() {
      let lowered = false;
      let extended = false;
      if (this.refs.drawer.open) {
        this.refs.fabDrawer.append(this.refs.fabHolder);
        lowered = true;
        extended = true;
      } else if (this.refs.rail.open) {
        lowered = true;
        this.refs.fabRail.append(this.refs.fabHolder);
      } else {
        this.refs.fabContainer.append(this.refs.fabHolder);
      }
      for (const fab of this.refs.fabHolder.querySelectorAll('mdw-fab')) {
        fab.lowered = lowered;
        fab.extended = extended;
      }
    },
  })
  .on({
    constructed() {
      // TODO: fix init value;
      this.links = this.links.slice();
      setFabContainer(this.refs.fabHolder);

      loginStateChangedEvent.on(this.checkLoginState.bind(this));
      pageChangeEvent.on(({ newUrl, isInternal, isHash }) => {
        if (!isInternal) return;
        if (isHash) {
          if (newUrl.hash === '#nav') {
            this.refs.drawer.toggleAttribute('open');
          }
          return;
        }

        const [page] = /\/[^/]*/.exec(newUrl.pathname);
        this.page = page;
        this.checkFabPosition();
      });
      this.checkLoginState();
      profileUpdatedEvent.on((profile) => {
        this.profile = { ...profile };
      });
    },
    loggedInChanged(oldValue, newValue) {
      if (!newValue) {
        navigate('/login', true);
        return;
      }
      const firstLink = this.links.find((link) => this.isPageAccessible(link.href));
      navigate(firstLink?.href || '/profile', true);
    },
  })
  .autoRegister('app-root');
