/**
 * @param {any} value
 * @return {number|null}
 */
export function parseNullableNumber(value) {
  const valueAsNumber = Number.parseInt(value, 10);
  if (Number.isNaN(valueAsNumber)) return null;
  return valueAsNumber;
}

/**
 * @param {any} value
 * @return {boolean|null}
 */
export function parseNullableBoolean(value) {
  switch (value) {
    case 'true':
    case 'TRUE':
    case 'yes':
    case 'YES':
    case '1':
    case '1.0':
    case 1:
    case true:
      return true;
    case 'false':
    case 'FALSE':
    case 'no':
    case 'NO':
    case '0':
    case '0.0':
    case 0:
      return false;
    default:
      return null;
  }
}

/**
 * @param {any} value
 * @return {string|null}
 */
export function parseNullableString(value) {
  if (value === '') return null;
  if (value == null) return null;
  return `${value}`;
}
