/**
 * 5.  Each field may or may not be enclosed in double quotes (however
 *     some programs, such as Microsoft Excel, do not use double quotes
 *     at all).  If fields are not enclosed with double quotes, then
 *     double quotes may not appear inside the fields.  For example:
 *
 *     - "aaa","bbb","ccc" CRLF
 *     - zzz,yyy,xxx
 *
 * 6.  Fields containing line breaks (CRLF), double quotes, and commas
 *     should be enclosed in double-quotes.  For example:
 *
 *     - "aaa","b CRLF
 *     - bb","ccc" CRLF
 *     - zzz,yyy,xxx
 *
 * 7.  If double-quotes are used to enclose fields, then a double-quote
 *     appearing inside a field must be escaped by preceding it with
 *     another double quote.  For example:
 *
 *     - "aaa","b""bb","ccc"
 * @param {string} value
 * @return {string}
 */
function escapeValue(value) {
  if (!value) return '';
  if (!/[\n\r",]/.test(value)) return value;
  return `"${value.replaceAll('"', '""')}"`;
}

/**
 * @param {string} camelCase
 * @return {string}
 */
function snakeCaseFromCamelCase(camelCase) {
  return camelCase.replaceAll(/[A-Z]/g, (substring) => `_${substring.toLowerCase()}`);
}

/**
 * @see https://www.ietf.org/rfc/rfc4180.txt
 * @param {Record<string, any>[]} json
 * @param {Object} [options]
 * @param {boolean} [options.dateLocal] Use local date time string
 * @param {boolean} [options.keepCamelCase] Don't convert to snake_case
 */
export function csvFromJSON(json, options = {}) {
  /** @type {Set<string>} */
  const columns = new Set();

  /** @type {Map<string, string>} */
  let map = new Map();
  /** @type {Map<string, string>[]} */
  const rows = [];

  /**
   * @param {string} key
   * @param {any} value
   * @param {string} [prefix]
   * @return {void}
   */
  const processPair = (key, value, prefix = '') => {
    if (value == null) return;
    const isArray = Array.isArray(value);

    if (!isArray && typeof value === 'object' && value instanceof Date === false) {
      for (const [k, v] of Object.entries(value)) {
        processPair(k, v, `${key}_`);
      }
      return;
    }

    let columnName = prefix + key;
    if (!options.keepCamelCase) {
      columnName = snakeCaseFromCamelCase(columnName);
    }
    let i = 1;
    while (map.has(columnName)) {
      i += 1;
      columnName = prefix + key + i;
    }
    columns.add(columnName);
    let stringValue;
    if (isArray) {
      if (value.some((v) => typeof v !== 'string')) {
        throw new TypeError('Subarrays are not supported.');
      }
      stringValue = value.map((v) => escapeValue(v)).join(',');
    } else if (value instanceof Date) {
      stringValue = options.dateLocal ? value.toLocaleString() : value.toISOString();
    } else {
      stringValue = `${value}`;
    }
    map.set(columnName, stringValue);
  };
  for (const row of json) {
    for (const [key, value] of Object.entries(row)) {
      processPair(key, value);
    }
    rows.push(map);
    map = new Map();
  }

  const columnsArray = [...columns];
  /**
   * 3. There maybe an optional header line appearing as the first line
   * of the file with the same format as normal record lines.  This
   * header will contain names corresponding to the fields in the file
   * and should contain the same number of fields as the records in
   * the rest of the file (the presence or absence of the header line
   * should be indicated via the optional "header" parameter of this
   * MIME type).  For example:
   *
   * - field_name,field_name,field_name CRLF
   * - aaa,bbb,ccc CRLF
   * - zzz,yyy,xxx CRLF
   *
   * 4. Within the header and each record, there may be one or more
   * fields, separated by commas.  Each line should contain the same
   * number of fields throughout the file.  Spaces are considered part
   * of a field and should not be ignored.  The last field in the
   * record must not be followed by a comma.  For example:
   *
   * - aaa,bbb,ccc
   */
  const header = columnsArray
    .map((column) => escapeValue(column)).join(',');
  const content = rows
    .map((row) => columnsArray
      .map((column) => escapeValue(row.get(column))).join(','));

  /**
   * 1. Each record is located on a separate line, delimited by a line
   * break (CRLF).  For example:
   *
   *  - aaa,bbb,ccc CRLF
   *  - zzz,yyy,xxx CRLF
   *
   * 2. The last record in the file may or may not have an ending line
   * break.  For example:
   *
   *  - aaa,bbb,ccc CRLF
   *  - zzz,yyy,xxx
   */

  const csv = [header, ...content].join('\r\n');
  return csv;
}

/**
 * @param {string} csv
 * @return {string[]} fields
 */
export function parseCSVRecord(csv) {
  const fields = [];

  let fieldStart = 0;
  let fieldStop = 0;
  let inQuotes = false;
  let quoteInQuote = false;
  let useFieldBuffer = false;
  let fieldBuffer = '';
  for (let position = 0; position < csv.length; position++) {
    const character = csv[position];
    if (quoteInQuote) {
      // Next character must terminate field (,) or indicate quote
      if (character === '"') {
        if (useFieldBuffer) {
          fieldBuffer += '"'; // Add quote to buffer
        } else {
          useFieldBuffer = true; // Start using field buffer
          fieldBuffer = `${csv.slice(fieldStart, fieldStop)}"`;
        }
        quoteInQuote = false; // No longer quote in quote
      } else if (character === ',') {
        // Close
        if (useFieldBuffer) {
          fields.push(fieldBuffer); // Store buffer
          useFieldBuffer = false;
        } else {
          fields.push(csv.slice(fieldStart, fieldStop)); // Store via offsets
        }
        fieldStart = position + 1;
        fieldStop = fieldStart;
        quoteInQuote = false;
        inQuotes = false;
      } else {
        throw new Error(`Unexpected character '${character}' at position: ${position}.)`);
      }
    } else if (inQuotes) {
      if (character === '"') {
        // Signal quote, but don't increment counter
        quoteInQuote = true;
      } else if (useFieldBuffer) {
        fieldBuffer += character;
      } else {
        // Increment counter
        fieldStop++;
      }
    } else if (character === ',') {
      // Close
      fields.push(csv.slice(fieldStart, fieldStop));
      fieldStart = position + 1;
      fieldStop = fieldStart;
      continue;
    } else if (character === '"') {
      if (fieldStart !== fieldStop) {
        // Fields may not unexpectedly have have double quotes inside
        throw new Error(`Unexpected double quotes '${character}' at position: ${position}.)`);
      }
      fieldStart = position + 1;
      fieldStop = fieldStart;
      inQuotes = true;
    } else {
      fieldStop++;
    }
  }
  // Auto terminate
  if (quoteInQuote) {
    if (useFieldBuffer) {
      fields.push(fieldBuffer); // Store buffer
      useFieldBuffer = false;
    } else {
      fields.push(csv.slice(fieldStart, fieldStop)); // Store via offsets
    }
    quoteInQuote = false;
    inQuotes = false;
  } else if (inQuotes) throw new Error('Unexpected end of record.');
  else if (useFieldBuffer) throw new Error('Unexpected end of record.');
  else if (fieldStop === csv.length) {
    fields.push(csv.slice(fieldStart, fieldStop));
  } else if (fieldStart !== fieldStop) throw new Error('Unexpected end of record.');

  return fields;
}

/**
 * @param {string[]} records
 * @param {string[]} [headers]
 * @return {Record<string,string>[]}
 */
export function parseCSVRecords(records, headers) {
  let parsedHeaders = headers;
  /** @type {Record<string,string>[]} */
  const parsedRecords = [];
  for (const record of records) {
    const trimmed = record.trim();
    if (!trimmed) continue; // skip empty
    const parsedRecord = parseCSVRecord(trimmed);
    if (!parsedHeaders) {
      parsedHeaders = parsedRecord;
      continue;
    }
    const entries = [];
    for (const [index, field] of parsedRecord.entries()) {
      if (index === parsedHeaders.length) {
        throw new Error('Invalid record count.');
      }
      entries.push([parsedHeaders[index], field]);
    }
    const parsedObject = Object.fromEntries(entries);
    parsedRecords.push(parsedObject);
  }
  return parsedRecords;
}

/**
 * @param {string} csv
 * @param {string[]} [headers]
 */
export function parseCSVFile(csv, headers) {
  return parseCSVRecords(csv.split(/\r?\n/g), headers);
}

export const jsonToCSV = csvFromJSON;
