import EventDispatcher from '../../utils/EventDispatcher.js';

/**
 * @typedef PageChangeEventDetail
 * @prop {URL} oldUrl
 * @prop {URL} newUrl
 * @prop {string[]} segments
 * @prop {boolean} isInternal
 * @prop {boolean} isRefresh
 * @prop {boolean} isHash
 */

/**
 * @template T
 * @typedef {import( '../../utils/EventDispatchHandler').default<T>} EventDispatcherHandler
 */

const pageEventDispatcher = new EventDispatcher('app:page');

/** @type {EventDispatcherHandler<PageChangeEventDetail>} */
export const pageChangeEvent = pageEventDispatcher.createHandler('app:pagechange');

let currentHistoryIndex = 0;
let currentUrl = new URL(window.location.href);
/**  @type {{nonce: string, url: string}[]} */
const historyStates = [];

/**
 * @param {URL} oldUrl
 * @param {URL} newUrl
 * @return {PageChangeEventDetail}
 */
function createPageChangeEventDetails(oldUrl, newUrl) {
  const isRefresh = oldUrl.href === newUrl.href;
  const isInternal = isRefresh || new URL('/', oldUrl).href === new URL('/', newUrl).href;
  const isHash = !isRefresh
        && isInternal
        && oldUrl.hash !== newUrl.hash;
  const segments = newUrl.pathname.split('/');
  return { oldUrl, newUrl, segments, isRefresh, isInternal, isHash };
}

/** @param {PopStateEvent} event */
function onPopState(event) {
  const requestedIndex = historyStates.findIndex((s) => s.nonce === event.state?.nonce);
  if (requestedIndex === -1 && currentHistoryIndex !== 0) {
    console.warn('onPopState: unrecognized state!');
    // UNRECOGNIZED STATE
    return;
  }

  const newUrl = new URL(event.state.url);
  const previousState = historyStates[requestedIndex + 1];
  const oldUrl = new URL(previousState?.url ?? newUrl);

  if (!pageChangeEvent.emit(createPageChangeEventDetails(oldUrl, newUrl))) {
    return;
  }
  currentHistoryIndex = requestedIndex;
}

/**
 * @param {URL} oldUrl
 * @param {URL} newUrl
 * @param {boolean} [replace]
 * @return {boolean} Returns true if native operation should continue
 */
function onNavigate(oldUrl, newUrl, replace) {
  const detail = createPageChangeEventDetails(oldUrl, newUrl);
  const prevented = pageChangeEvent.emit(detail) === false;
  if (detail.isHash) return false; // Don't rewrite url
  if (!prevented) {
    currentUrl = newUrl;
  }
  if (!detail.isInternal) {
    if (prevented) return false;
    return true;
  }

  const nonce = Math.random().toString(36).slice(2, 18);
  const state = { nonce, url: newUrl.href };
  if (replace) {
    // This should replace the current nav state
    window.history.replaceState(state, '');
    if (historyStates.length === 0) {
      historyStates.push(state);
    } else {
      historyStates[historyStates.length - 1] = state;
    }
  } else {
    window.history.pushState(state, '');
    currentHistoryIndex++;
    historyStates.push(state);
  }
  return false;
}

/**
 * @param {URL|string} url
 * @param {boolean} [replace]
 * @return {boolean} prevented
 */
export function navigate(url, replace) {
  const oldUrl = new URL(currentUrl);
  const newUrl = new URL(url, currentUrl);
  return onNavigate(oldUrl, newUrl, replace);
}

/** @return {void} */
export function start() {
  window.addEventListener('popstate', onPopState);
  document.addEventListener('mdw:hyperlink', (/** @type {CustomEvent<Partial<HTMLAnchorElement>>} */event) => {
    if (event.detail.download) return;
    if (event.detail.target === '_blank') return;
    const oldUrl = new URL(currentUrl);
    const newUrl = new URL(event.detail.href, currentUrl);
    if (newUrl.protocol !== 'https:' && newUrl.protocol !== 'http:') return;
    if (!onNavigate(oldUrl, newUrl)) {
      event.preventDefault();
    }
  });

  document.addEventListener('click', (event) => {
    if (event.target instanceof HTMLAnchorElement === false) return;
    if (event.target.download) return;
    if (event.target.target === '_blank') return;
    const oldUrl = new URL(currentUrl);
    const newUrl = new URL(event.target.href, currentUrl);
    if (!onNavigate(oldUrl, newUrl)) {
      event.preventDefault();
    }
  });
}
