/**
 * @template T
 * @typedef {import("./EventDispatcher.js").default<T>} EventDispatcher
 */

/** @template T */
export default class EventDispatcherHandler {
  /**
   * @param {EventDispatcher<any>} eventDispatcher
   * @param {string} type
   */
  constructor(eventDispatcher, type) {
    this.eventDispatcher = eventDispatcher;
    this.type = type;
  }

  /**
   * @param {T=} detail
   * @return {CustomEvent<T>}
   */
  createEvent(detail) {
    return this.eventDispatcher.createEvent(this.type, detail);
  }

  /**
   * @param {CustomEvent<T>} event
   * @return {boolean}
   */
  dispatchEvent(event) {
    return this.eventDispatcher.dispatchEvent(event);
  }

  /**
   * @param {function(CustomEvent<T>):any} callback
   * @return {void}
   */
  addEventListener(callback) {
    this.eventDispatcher.addEventListener(this.type, callback);
  }

  /**
   * @param {function(CustomEvent<T>):any} callback
   * @return {void}
   */
  removeEventListener(callback) {
    this.eventDispatcher.removeEventListener(this.type, callback);
  }

  /**
   * @param {T=} detail
   * @return {boolean}
   */
  emit(detail) {
    return this.dispatchEvent(this.createEvent(detail));
  }

  /**
   * @param {function(T):any} callback
   * @return {function(T):any} callback
   */
  on(callback) {
    return this.eventDispatcher.on(this.type, callback);
  }

  /**
   * @param {function(T):any} callback
   * @return {function(T):any} callback
   */
  once(callback) {
    return this.eventDispatcher.once(this.type, callback);
  }

  /**
   * @param {function(T):any} callback
   * @return {void}
   */
  off(callback) {
    this.eventDispatcher.off(this.type, callback);
  }
}
