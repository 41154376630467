import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Headline.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { createDelegate } from '../../delegate.js';

export default CustomElement
  .extend()
  .observe({
    open: 'boolean',
  })
  .html`
    <mdw-page>
    <form id=form>
      <fieldset id=fieldset>
      <mdw-card x=stretch y=center padding=24 gap=24 elevated id=add-card>
        <mdw-headline text-trim>Add New Guest</mdw-headline>
        <mdw-input name=label label="Name*" outlined supporting="*required" required></mdw-input>
        <mdw-input name=email label="Email" outlined type=email></mdw-input>
        <mdw-input name=phoneNumber label="Phone Number" outlined type=tel maxlength=15></mdw-input>
        <mdw-box row x=end gap=8>
          <mdw-button id=cancel filled=tonal>Cancel</mdw-button>
          <mdw-button id=save   type=submit filled>Save</mdw-button>
        </mdw-box>
      </mdw-card>
      </fieldset>
    </form>
    </mdw-page>

  `
  .css`
    mdw-page {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      block-size: 100%;
      padding: 24px;
    }

    #add-card {
      inline-size: 100%;
      max-inline-size: 80ch;
    }

    form, fieldset {
      display: contents;
    }
  `
  .methods({
    close() {
      this.dispatchEvent(new Event('close'));
      this.open = false;
    },
    focus() {
      this.refs.label.focus();
    },
  })
  .childEvents({
    cancel: {
      click(event) {
        this.close();
      },
    },
    form: {
      async submit(event) {
        event.preventDefault();

        const form = /** @type {HTMLFormElement} */(event.currentTarget);
        const formData = new FormData(form);
        const formEntries = [...formData.entries()];
        const delegateValues = /** @type {Record<keyof Delegate,string>} */ (Object.fromEntries(formEntries));

        try {
          // Fetch record from Server using delegate service
          if (!this.open) return; // UI is gone

          /** @type {Partial<Delegate>} */
          const newDelegate = {
            ...delegateValues,
            active: true,
          };

          await createDelegate(newDelegate);
          this.close();
        } catch (e) {
          console.log(e);
        }
      },
      keydown(event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
    },
  })
  .on({
    openChanged(oldValue, open) {
      if (open) {
        // Reset on open
        const form = /** @type {HTMLFormElement} */ (this.refs.form);
        form.reset();
      }
    },
  })
  .autoRegister('add-delegates');
