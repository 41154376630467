import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Headline.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { login } from '../../auth.js';

/** @typedef {import('@shortfuse/materialdesignweb/components/Button.js').default} Button */

export default CustomElement
  .extend()
  .html`
    <mdw-page>
    <form id=login-form>
      <fieldset id=fieldset>
      <mdw-card x=stretch y=center padding=24 gap=16 elevated id=login-card>
        <mdw-headline text-trim>Login</mdw-headline>
        <mdw-input id=username required supporting filled name=username label="Username" autocomplete=username></mdw-input>
        <mdw-input id=password required supporting filled name=password label="Password" autocomplete=current-password type=password></mdw-input>
        <mdw-box row x=between>
          <mdw-button id=reset-password type=button>Reset Password</mdw-button>
          <mdw-button filled id=submit type=submit>Login</mdw-button>
        </mdw-box>
      </mdw-card>
      </fieldset>
    </form>
    </mdw-page>
  `
  .css`
    mdw-page {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      block-size: 100%;
      padding: 24px;
    }

    #login-card {
      inline-size: 80ch;
      flex: 0 1 auto;
    }

    form, fieldset {
      display: contents;
    }
  `
  .childEvents({
    password: {
      input({ currentTarget }) {
        /** @type {HTMLInputElement} */ (currentTarget).setCustomValidity('');
      },
    },
    loginForm: {
      async submit(event) {
        event.preventDefault();
        /** @type {HTMLFormElement} */
        const form = event.currentTarget;
        const formData = new FormData(form);
        const username = formData.get('username');
        const password = formData.get('password');

        const fieldset = /** @type {HTMLFieldSetElement} */ (this.refs.fieldset);
        // GO ASYNC
        try {
          fieldset.disabled = true;
          await login(username, password);
          fieldset.disabled = false;
          form.reset();
        } catch (e) {
          fieldset.disabled = false;
          const passwordElement = /** @type {HTMLInputElement} */ (this.refs.password);
          switch (e.status) {
            default:
              passwordElement.setCustomValidity('An error occurred. Please try again.');
              passwordElement.focus();
              passwordElement.select();
              break;
            case 403:
              passwordElement.setCustomValidity('Invalid username and password combination.');
              passwordElement.focus();
              passwordElement.select();
          }
          console.log(e);
        }
      },
    },
  })
  .autoRegister('login-page');
