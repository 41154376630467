import './custom.js';
/* eslint-disable no-unused-expressions */
import AppRoot from './AppRoot.js';

/** @return {Promise<void>} */
export function startUIService() {
  AppRoot;
}

/** @return {Promise<void>} */
export function stopUIService() {}
