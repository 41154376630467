import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Listbox.js';
import '@shortfuse/materialdesignweb/components/ListOption.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { pageChangeEvent } from '../../page.js';

import './ScansList.js';
import './ScansDetail.js';

export default CustomElement
  .extend()
  .html`
    <mdw-page id=page pane-one=fixed pane-two=flexible>
      <scans-list id=list></scans-list>
      <scans-detail id=details slot=two></scans-detail>
    </mdw-page>
  `
  .css`
    mdw-page {
      display: grid;
    }
  `
  .on({
    constructed() {
      pageChangeEvent.on(({ isInternal, segments }) => {
        if (!isInternal) return;
        const [root, page, uri] = segments;
        if (page !== 'scans') return;
        this.refs.page.paneTwoActive = !!uri;
        this.refs.details.uri = uri;
      });
    },
    async connected() {
      console.log('scans-page connected');
    },
  })
  .autoRegister('scans-page');
