import '@shortfuse/materialdesignweb/components/Body.js';
import '@shortfuse/materialdesignweb/components/Label.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/Listbox.js';
import '@shortfuse/materialdesignweb/components/ListOption.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { pageChangeEvent } from '../../page.js';
import { createScan, queryScanCheckpoints, queryScans } from '../../scan.js';

export default CustomElement
  .extend()
  .observe({
    uri: 'string',
    checkpoints: {
      type: 'object',
      reflect: false,
      /** @type {ScanCheckpoint[]} */
      value: null,
    },
    scans: {
      type: 'object',
      reflect: false,
      /** @type {Scan[]} */
      value: null,
    },
    _currentCheckpointId: 'integer',
    _currentCheckpointName: 'string',
  })
  .set({
    _checkpointDebouncer: null,
  })
  .expressions({
    describeScan(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Unrecognized Ticket';
      if (scanObject.allowed || scanObject.ticketRanges?.length) return `${scanObject.delegateLabel || 'Ticket'} - ${scanObject.ticketId}`;
      return `${scanObject.delegateLabel || 'Unrecognized Ticket'} - ${scanObject.ticketId}`;
    },
    describeScan2(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'Scan Error';
      if (scanObject.allowed) return 'Valid Ticket';
      if (scanObject.ticketRanges?.length) return `No Entry - Range: ${scanObject.ticketRanges[0].name}`;
      return 'Ticket not found in system';
    },
    describeScan3(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return '';
      if (scanObject.delegateGroups) return `${scanObject.delegateGroups.map(({ name }) => name).join(', ')}`;
      return '';
    },
    computedHref(data, { scan }) {
      if (!scan) return '';
      return `/delegates/${scan.delegateId}`;
    },
    scanIcon(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'help';
      if (scanObject.allowed) return 'check_circle';
      if (scanObject.ticketRanges?.length) return 'cancel';
      return 'help';
    },
    scanInk(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject) return 'orange';
      if (scanObject.allowed) return 'green';
      if (scanObject.ticketRanges?.length) return 'red';
      return 'orange';
    },
    computeScanTimestamp(data, { scan }) {
      const scanObject = /** @type {Scan} */ (scan);
      if (!scanObject?.timestamp) return '';
      const asDate = new Date(scanObject.timestamp);
      const timeSince = Date.now() - scanObject.timestamp;
      if (timeSince < 24 * 60 * 60 * 1000) {
        return asDate.toLocaleTimeString();
      }
      return `${asDate.toLocaleDateString()}\n${asDate.toLocaleTimeString()}`;
    },
  })
  .html`
  <mdw-pane padding=pane id=pane flex-1>
    <mdw-box mdw-if={!uri} flex-1 x=center y=center>
      <mdw-headline padding-y=16><mdw-icon icon=barcode_scanner>Scans</mdw-icon>No checkpoint selected.</mdw-headline>
    </mdw-box>
    <mdw-top-app-bar mdw-if={!!uri}>
      <mdw-icon-button class="toolbar-icon-button" slot="leading" icon="arrow_back" tabindex="0" on-click={onBack}>Back</mdw-icon-button>
      <span><mdw-inline ink=primary>{_currentCheckpointName}<mdw-inline></span>
    </mdw-top-app-bar>
    <mdw-box mdw-if={!!uri} padding-y=16 gap=16>
      <form id=form>
        <mdw-box row gap=16 x=center y=center>
          <mdw-input id=input outlined label="Ticket Scan" size=12 required placeholder="Waiting for autoscan..."></mdw-input>
          <mdw-button icon=send type=submit>Send Scan</mdw-icon-button>
        </mdw-box>
      </mdw-form>
      <mdw-divider ink=primary></mdw-divider>
      <mdw-list>
        <mdw-list-item mdw-for="{scan of scans}" icon={scanIcon} icon-ink={scanInk} href={computedHref}>
        <mdw-box class=scan-item-indicator color={scanInk}></mdw-box>
        <span>{describeScan}</span>
        <mdw-box block gap=8 slot=supporting>
          <mdw-body size=medium>{describeScan2}</mdw-body>
          <mdw-body size=small>{describeScan3}</mdw-body>
        </mdw-box>
        <span class=scan-item-trailing slot=trailing>{computeScanTimestamp}</span>
        </mdw-list-item>
      </mdw-list>
    </mdw-box>
  </mdw-pane>
  `
  .css`
    mdw-page {
      display: grid;
    }
    form {
      display: contents;
    }

    .scan-item-trailing {
      text-align: end;
      white-space: pre;
    }

    mdw-list-item {
      position: relative;
    }

    .scan-item-indicator {
      position: absolute;
      inset-block: 0;
      inset-inline-start: 0;

      inline-size: 4px;

    }
  `
  .methods({
    onBack() { window.history.back(); },
    async updateScans(newValue) {
      this._currentCheckpointId = newValue;
      const checkPointId = this._currentCheckpointId;
      this.checkpoints = checkPointId ? await queryScanCheckpoints({ checkpointId: checkPointId }) : [];
      this._currentCheckpointName = `${this.checkpoints[0].eventName} - ${this.checkpoints[0].name}`;
      this.scans = checkPointId ? (await queryScans({ checkpointId: checkPointId, scannerId: 'me' })) : [];
    },
  })
  .childEvents({
    form: {
      async submit(event) {
        event.preventDefault();

        /** @type {Partial<Scan>} */
        const partialScan = {
          checkpointId: this._currentCheckpointId,
          ticketId: this.refs.input.value,
        };
        this.refs.input.value = '';
        this.refs.input.focus();
        const result = await createScan(partialScan);
        await this.updateScans(this._currentCheckpointId);
        console.log(result);
      },
    },
  })
  .on({
    uriChanged(oldValue, newValue) {
      this.updateScans(newValue).catch(() => {});
    },
    async connected() {
      console.log('connected scans details');
    },

  })
  .autoRegister('scans-detail');
