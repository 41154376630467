import FabContainer from '@shortfuse/materialdesignweb/components/FabContainer.js'


/** @type {InstanceType<FabContainer>} */
let container;

export function setFabContainer(element) {
  container = element;
}

export function getFabContainer() {
  return container;
}