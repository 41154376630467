import { startAuthService } from './services/auth.js';
import * as pageService from './services/page.js';
import { startUIService } from './services/ui/index.js';

/** @return {void} */
function main() {
  console.log('Loaded JS');
  pageService.start();
  startUIService();
  startAuthService();
}

main();
