import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/IconButton.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/TextArea.js';
import '@shortfuse/materialdesignweb/components/SegmentedButtonGroup.js';
import '@shortfuse/materialdesignweb/components/SegmentedButton.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/List.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { fetchTicketRange, updateTicketRange } from '../../tickets.js';

export default CustomElement
  .extend()
  .observe({
    uri: 'string',
  })
  .set({
    /** @type {number} */
    lastFetchedId: null,
    /** @type {AbortController} */
    fetchAbortController: null,
    /** @type {TicketRange} */
    ticket: null,

  })
  .observe({
    infoEditable: 'boolean',
  })
  .expressions({
    canEditInfo() {
      // Check user permisions
      return true;
    },
    infoEditButtonText({ infoEditable }) {
      return infoEditable ? 'Discard' : 'Edit';
    },
  })
  .html`
    <mdw-pane padding=pane id=pane flex-1>
      <mdw-box mdw-if={!uri} flex-1 x=center y=center>
        <mdw-headline padding-y=16><mdw-icon icon=local_activity>Ticket</mdw-icon>No ticket range selected.</mdw-headline>
      </mdw-box>
      <mdw-top-app-bar mdw-if={!!uri}>
        <mdw-icon-button class="toolbar-icon-button" slot="leading" icon="arrow_back" tabindex="0" on-click={onBack}>Back</mdw-icon-button>
        <span><mdw-inline ink=primary>{ticket.name}<mdw-inline></span>
      </mdw-top-app-bar>
      <mdw-box mdw-if={!!uri} flex-1 padding-y=16 gap=16>
        <mdw-card id=info-card elevated={!infoEditable} outlined={infoEditable} gap=16 padding=16>
          <form id=info-form>
            <mdw-box row x=between y=start>
              <mdw-headline>Info</mdw-headline>
              <mdw-box mdw-if={canEditInfo} row x=between y=start ink=primary gap=8 >
                <mdw-button id=info-edit-button  type=button filled=tonal mdw-if={!infoEditable}  >Edit</mdw-button>
                <mdw-button id=info-reset-button type=reset  filled=tonal mdw-if={infoEditable}   >Discard</mdw-button>
                <mdw-button id=info-save-button  type=submit filled       disabled={!infoEditable}>Save</mdw-button>
              </mdw-box>
            </mdw-box>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=name value={ticket.name}           id=info-name  label="Range Name"></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=rangeStart value={ticket.rangeStart} id=info-start label="Start Range" ></mdw-input>
            <mdw-input readonly={!infoEditable} filled={!infoEditable} outlined={infoEditable} name=rangeEnd   value={ticket.rangeEnd}   id=info-end   label="End Range" ></mdw-input>
          </form>
        </mdw-card>
      </mdw-box>
    </mdw-pane>
  `
  .css`
    :host {
      flex: 1;
    }

    form {
      display: contents;
    }

    #pane {
      display: flex;
      flex-direction: column;
    }
  `
  .methods({
    onBack() { window.history.back(); },
    async refreshEvent() {
      if (!this.uri) return;
      try {
        // Convert URI to number
        const id = Number.parseInt(this.uri, 10);
        // Fetch record from Server using TicketRange service
        const ticket = await fetchTicketRange(id);

        // Attach object to current element state
        this.ticket = ticket;

        // Update all HTML
        this.render({ ticket: this.ticket });

        // Reset forms
        this.refs.infoForm.reset();
      } catch (e) {
        console.error(e);
      }
    },
  })
  .childEvents({
    infoForm: {
      async submit(event) {
        event.preventDefault();
        const form = /** @type {HTMLFormElement} */ (event.currentTarget);
        const formData = new FormData(form);
        /** @type {Partial<TicketRange>} */
        const updateInfo = Object.fromEntries(formData.entries());
        console.log(updateInfo);

        await updateTicketRange(this.ticket.ticketRangeId, updateInfo);
        await this.refreshEvent();
        this.infoEditable = false;
      },
      reset() {
        this.infoEditable = false;
      },
      keydown(event) {
        if (event.key === 'Escape') {
          if (!this.infoEditable) return;
          event.preventDefault();
          const form = /** @type {HTMLFormElement} */ (event.currentTarget);
          form.reset();
        }
      },
    },
    infoEditButton: {
      click() {
        this.infoEditable = true;
      },
    },
  })
  .on({
    uriChanged(oldValue, newValue) {
      this.refreshEvent().catch(() => {});
    },
    connected() {
      console.log('connected event details');
    },
    infoEditableChanged(oldValue, isEditable) {
      if (isEditable) {
        const form = /** @type {HTMLFormElement} */(this.refs.infoForm);
        const input = /** @type {HTMLInputElement} */ (form.elements.namedItem('ticketRangeId'));
        input?.focus();
        input?.select();
      } else if (this.refs.infoResetButton.matches(':focus')) {
        this.refs.infoEditButton.focus();
      }
    },
  })
  .autoRegister('tickets-detail');
