import '@shortfuse/materialdesignweb/components/Box.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Headline.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/FilterChip.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Search.js';
import '@shortfuse/materialdesignweb/components/Switch.js';
import '@shortfuse/materialdesignweb/components/Tab.js';
import '@shortfuse/materialdesignweb/components/TabList.js';
import '@shortfuse/materialdesignweb/components/TabContent.js';
import '@shortfuse/materialdesignweb/components/TabPanel.js';
import '@shortfuse/materialdesignweb/components/Dialog.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { createJWEvent } from '../../jwevent.js';

export default CustomElement
  .extend()
  .observe({
    open: 'boolean',
    _existingEventId: 'boolean',
  })
  .html`
    <mdw-page>
    <form id=form>
      <fieldset id=fieldset>
      <mdw-card x=stretch y=center padding=24 gap=24 elevated id=add-card>
        <mdw-headline text-trim>Add New Event</mdw-headline>
        <mdw-input id=event-name name=eventName                     label="Event Name" outlined required></mdw-input>
        <mdw-input id=start-time name=startTime type=datetime-local label="Start Time" outlined></mdw-input>
        <mdw-input id=end-time   name=endTime   type=datetime-local label="End Time"   outlined></mdw-input>
        
        <mdw-box row x=end gap=8>
          <mdw-button id=cancel filled=tonal>Cancel</mdw-button>
          <mdw-button id=save   type=submit filled>Save</mdw-button>
        </mdw-box>
      </mdw-card>
      </fieldset>
    </form>
    </mdw-page>

  `
  .css`
    mdw-page {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      block-size: 100%;
      padding: 24px;
    }

    #add-card {
      inline-size: 100%;
      max-inline-size: 80ch;
    }

    form, fieldset {
      display: contents;
    }
  `
  .methods({
    close() {
      this.dispatchEvent(new Event('close'));
      this.open = false;
    },
    focus() {
      this.refs.eventName.focus();
    },
  })
  .childEvents({
    eventName: {
      input({ currentTarget }) {
        // Reset validity on change
        currentTarget.setCustomValidity('');
      },
    },
    cancel: {
      click(event) {
        this.close();
      },
    },
    form: {
      async submit(event) {
        event.preventDefault();

        const eventNameElement = /** @type {HTMLInputElement} */ (this.refs.eventName);

        const eventStartElement = /** @type {HTMLInputElement} */ (this.refs.startTime);
        const eventStart = eventStartElement.valueAsNumber;

        const eventEndElement = /** @type {HTMLInputElement} */ (this.refs.endTime);
        const eventEnd = eventEndElement.valueAsNumber;

        // build partial event record
        /** @type {Partial<JWEvent>} */
        const newEvent = {
          name: eventNameElement.value || null,
          startTimestamp: eventStart ?? null,
          endTimestamp: eventEnd ?? null,
        };

        console.log(newEvent);
        try {
          // TODO: track page stage
          await createJWEvent(newEvent);
        } catch (e) {
          // Abort if user left already (eg: timeout)
          if (!this.open) return; // UI is gone
          console.error(e);
          // Name is already in use
          // TODO: Validate HTTP CODE 409 error
          eventNameElement.setCustomValidity('Name already in use.');
          eventNameElement.reportValidity();
          return;
        }

        this.close();
      },
      keydown(event) {
        if (event.key === 'Escape') {
          this.close();
        }
      },
    },
  })
  .on({
    openChanged(oldValue, open) {
      if (open) {
        // Reset on open
        const form = /** @type {HTMLFormElement} */ (this.refs.form);
        this._existingEventId = false;
        form.reset();
      }
    },
  })
  .autoRegister('add-events');
