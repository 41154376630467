import '@shortfuse/materialdesignweb/components/Page.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { pageChangeEvent } from '../../page.js';
import { getFabContainer } from '../fabManager.js';

import './TicketsList.js';
import './TicketsDetail.js';
import './AddTickets.js';

export default CustomElement
  .extend()
  .observe({
    addingTickets: 'boolean',
  })
  .html`
    <mdw-page mdw-if={!addingTickets} id=page pane-one=fixed pane-two=flexible>
      <tickets-list id=list></tickets-list>
      <tickets-detail id=details slot=two></tickets-detail>
    </mdw-page>
    <mdw-page mdw-if={addingTickets} id=addpage>
      <add-tickets id=add-new open={addingTickets}></add-tickets>
    </mdw-page>
    <mdw-fab mdw-if={!addingTickets} id=fab color=primary-container icon=add>Add Ticket</mdw-fab>
  `
  .css`
    mdw-page {
      display: grid;
    }
  `
  .childEvents({
    fab: {
      click(event) {
        console.log('addbutton clicked');
        const isAdding = this.addingTickets;
        this.addingTickets = !isAdding;
      },
    },
    addNew: {
      close(event) {
        this.addingTickets = false;
        console.log('i received a close event', event);
      },
    },
  })
  .on({
    constructed() {
      pageChangeEvent.on(({ isInternal, segments }) => {
        if (!isInternal) return;
        const [root, page, uri] = segments;
        if (page !== 'tickets') return;
        this.refs.page.paneTwoActive = !!uri;
        this.refs.details.uri = uri;
      });
    },
    connected() {
      console.log('tickets-page connected');
      const fab = this.refs.fab;
      fab.removeAttribute('id');
      getFabContainer().append(this.refs.fab);
    },
    disconnected() {
      this.refs.fab.remove();
    },
    addingTicketsChanged(oldValue, addingTickets) {
      if (addingTickets) {
        this.refs.addNew.focus();
      }
    },
  })
  .autoRegister('tickets-page');
