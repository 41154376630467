import { apiFetch } from './api.js';
import { API_BASE_URL } from './constants.js';

const SCAN_CHECKPOINTS_API = new URL('scancheckpoints/', API_BASE_URL);

/**
 * @param {Partial<ScanCheckpoint>} scanCheckpoint
 * @return {Promise<ScanCheckpoint>}
 */
export async function createScanCheckpoint(scanCheckpoint) {
  const url = new URL(SCAN_CHECKPOINTS_API);
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(scanCheckpoint),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {ScanCheckpoint} ScanCheckpoint
 * @return {Promise<ScanCheckpoint>}
 */
export async function putScanCheckpoint(ScanCheckpoint) {
  const url = new URL(ScanCheckpoint.checkpointId.toString(10), SERVER_API);
  const response = await apiFetch(url, {
    method: 'PUT',
    body: JSON.stringify(ScanCheckpoint),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<ScanCheckpoint>} [partialScanCheckpoint]
 * @return {Promise<ScanCheckpoint[]>}
 */
export async function queryScanCheckpoints(partialScanCheckpoint = {}) {
  const url = new URL('scancheckpoints/', API_BASE_URL);
  const { name, checkpointId, eventId } = partialScanCheckpoint;
  if (name != null) {
    url.searchParams.set('name', name.toString());
  }
  if (checkpointId != null) {
    url.searchParams.set('checkpointId', checkpointId.toString());
  }
  if (eventId != null) {
    url.searchParams.set('eventId', eventId.toString());
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {number} checkpointId
 * @return {Promise<ScanCheckpoint|null>}
 */
export async function fetchScanCheckpoint(checkpointId) {
  const url = new URL(checkpointId.toString(10), SCAN_CHECKPOINTS_API);
  const response = await apiFetch(url);
  if (response.ok) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<Scan>} scan
 * @return {Promise<ScanCheckpoint[]>}
 */
export async function createScan(scan) {
  const url = new URL('scans/', API_BASE_URL);

  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(scan),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }

  return null;
}

/**
 * @param {Partial<
 *  Omit<Scan, 'scannerId'> & {scannerId: 'me' | number}
 *  & {groupType:string, groupName:string}
 * >} partialScan
 * @return {Promise<Scan[]>}
 */
export async function queryScans(partialScan = {}) {
  const url = new URL('scans/', API_BASE_URL);
  const {
    timestamp,
    ticketId,
    checkpointId,
    scannerId,
    delegateId,
    allowed,
    checkpointName,
    eventId,
    eventName,
    delegateLabel,
    groupType,
    groupName,
  } = partialScan;

  if (timestamp != null) url.searchParams.set('timestamp', timestamp.toString());
  if (ticketId != null) url.searchParams.set('ticketId', ticketId.toString());
  if (checkpointId != null) url.searchParams.set('checkpointId', checkpointId.toString());
  if (scannerId != null) url.searchParams.set('scannerId', scannerId.toString());
  if (delegateId != null) url.searchParams.set('delegateId', delegateId.toString());
  if (allowed != null) url.searchParams.set('allowed', allowed.toString());
  if (checkpointName != null) url.searchParams.set('checkpointName', checkpointName.toString());
  if (eventId != null) url.searchParams.set('eventId', eventId.toString());
  if (eventName != null) url.searchParams.set('eventName', eventName.toString());
  if (delegateLabel != null) url.searchParams.set('delegateLabel', delegateLabel.toString());
  if (groupType != null) url.searchParams.set('groupType', groupType.toString());
  if (groupName != null) url.searchParams.set('groupName', groupName.toString());

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}
