import '@shortfuse/materialdesignweb/components/Button.js';
import '@shortfuse/materialdesignweb/components/Page.js';
import '@shortfuse/materialdesignweb/components/Pane.js';
import '@shortfuse/materialdesignweb/components/Card.js';
import '@shortfuse/materialdesignweb/components/Input.js';
import '@shortfuse/materialdesignweb/components/List.js';
import '@shortfuse/materialdesignweb/components/Progress.js';
import '@shortfuse/materialdesignweb/components/ListItem.js';
import CustomElement from '@shortfuse/materialdesignweb/core/CustomElement.js';

import { changePassword, logout, profile, profileUpdatedEvent } from '../../auth.js';
import { uploadImportDelegates } from '../../delegate.js';

export default CustomElement
  .extend()
  .observe({
    _profile: {
      type: 'object',
      /** @type {Delegate} */
      value: null,
    },
    _importDelegatesFileName: 'string',
  })
  .expressions({
    importFileButtonText({ _importDelegatesFileName }) {
      if (_importDelegatesFileName) return _importDelegatesFileName.slice('C:\\fakepath\\'.length);
      return 'Select File...';
    },
  })
  .html`
    <mdw-page id=page x=center>
      <mdw-pane gap=16 x=center padding=pane>
      <mdw-top-app-bar>
        <mdw-icon-button slot=leading icon=menu href="#nav">Toggle Nav</mdw-icon-button>
        Profile
      </mdw-top-app-bar>
      <mdw-card elevated x=stretch>
        <mdw-box row x=center padding=16>
          <mdw-title large ink=secondary>Actions</mdw-title>
        </mdw-box>
        <mdw-divider></mdw-divider>
        <mdw-list>
          <mdw-list-item icon=security id=change-password href=#>Change Password</mdw-list-item>
          <mdw-list-item icon=cancel icon-ink=error id=log-out href="#">
            <mdw-box ink=error>Log out</mdw-box></mdw-list-item>
        </mdw-list>
      </mdw-card>
      <mdw-card elevated x=stretch>
        <mdw-box row x=center padding=16>
          <mdw-title large ink=secondary>Help</mdw-title>
        </mdw-box>
        <mdw-divider></mdw-divider>
        <mdw-list>
          <mdw-list-item icon=help href="/static/help.pdf" target="_blank">Help Manual</mdw-list-item>
          <mdw-list-item icon=ios href="https://apps.apple.com/us/app/scankey-qr-ocr-nfc-keyboard/id1356206918" target="_blank">ScanKey</mdw-list-item>
          <mdw-list-item icon=android href="https://play.google.com/store/apps/details?id=com.tecit.android.barcodekbd.demo" target="_blank">Scanner Keyboard</mdw-list-item>
        </mdw-list>
      </mdw-card>
      <mdw-card elevated x=stretch mdw-if={!!_profile.roleSystemAdmin}>
        <mdw-box row x=center padding=16>
          <mdw-title large ink=secondary>Tools</mdw-title>
        </mdw-box>
        <mdw-divider></mdw-divider>
        <mdw-list>
          <mdw-list-item id=tools-import-delegates icon=upload href="#">Import Guests</mdw-list-item>
          <mdw-list-item id=tools-export-delegates icon=download href="/api/delegates/export.csv" download="export.csv" target="_blank" >Export Guests</mdw-list-item>
        </mdw-list>
      </mdw-card>
      <mdw-pane>
    </mdw-page>
    <mdw-dialog id=dialog-change-password icon=security headline="Change Password">
      <form id=dialog-change-password-form method=dialog>
        <mdw-box gap=16 padding=16>
          <mdw-input outlined minlength=4 id=dialog-change-password-current supporting name="current-password" autofocus autocomplete=current-password label="Current Password" type=password required></mdw-input>
          <mdw-input outlined minlength=4 id=dialog-change-password-new supporting name="new-password" autocomplete=new-password label="New Password" type=password required></mdw-input>
          <mdw-input outlined minlength=4 id=dialog-change-password-confirm supporting name="confirm-password" autocomplete=new-password label="Confirm Password" type=password required></mdw-input>
        </mdw-box>
      </form>
      <div slot=actions>
          <input form=dialog-change-password-form type=submit hidden value=confirm tabindex=-1 aria-hidden=true >
          <!-- Forms submit nearest type=submit control on ENTER key -->
          <mdw-button form=dialog-change-password-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=dialog-change-password-form type=submit value=confirm>Change</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-import-delegates icon=upload headline="Import Guests">
      <form id=dialog-import-delegates-form method=dialog>
        <mdw-box gap=16 padding=16>
          <mdw-button type=file id=dialog-import-delegates-file name=file autofocus icon=upload required accept="text/csv" outlined={!_importDelegatesFileName} filled={!!_importDelegatesFileName} value="">{importFileButtonText}</mdw-button>
          <mdw-input outlined id=dialog-import-delegates-confirm name=import-confirm required autocomplete=off label="Confirm" required placeholder="import" supporting="Please type 'import' to confirm this action."></mdw-input>
          <mdw-progress auto-hide value=0></mdw-progress>
        </mdw-box>
      </form>
      <div slot=actions>
          <input form=dialog-import-delegates-form type=submit hidden value=confirm tabindex=-1 aria-hidden=true >
          <!-- Forms submit nearest type=submit control on ENTER key -->
          <mdw-button form=dialog-import-delegates-form type=submit value=cancel formnovalidate>Close</mdw-button>
          <mdw-button form=dialog-import-delegates-form type=submit value=confirm>Import</mdw-button>
      </div>
    </mdw-dialog>
    <mdw-dialog id=dialog-log-out icon=cancel headline="Log out" confirm="Log out" cancel="Close" default="confirm">
      Log out of your session?
    </mdw-dialog>
  `
  .css`
    mdw-page {
      display: grid;
    }

    mdw-top-app-bar {
      inline-size: 100%;
    }

    mdw-card {
      min-inline-size: min(80ch,100%);
      max-inline-size: 80ch;
    }

    #dialog-change-password-form {
      display: contents;
    }
  `
  .methods({
    /**
     * @param {URL} url
     */
    handleUrl(url) {
      const uri = url.pathname.slice('/profile/'.length);

      this.refs.details.uri = uri;
    },
  })

  .childEvents({
    logOut: {
      'mdw:hyperlink'(event) {
        event.preventDefault();
        event.stopPropagation();
        this.refs.dialogLogOut.showModal();
      },
    },
    changePassword: {
      'mdw:hyperlink'(event) {
        event.preventDefault();
        event.stopPropagation();
        this.refs.dialogChangePasswordForm.reset();
        this.refs.dialogChangePassword.showModal();
      },
    },
    dialogLogOut: {
      async close(event) {
        /** @type {HTMLDialogElement} */
        const dialog = event.currentTarget;
        if (dialog.returnValue !== 'confirm') return;
        await logout();
      },
    },
    dialogChangePassword: {
      input({ target }) { target.setCustomValidity(''); },
      change({ target }) { target.setCustomValidity(''); },
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);
        const formData = new FormData(form);
        const currentPassword = formData.get('current-password');
        const newPassword = formData.get('new-password');
        const confirmPassword = formData.get('confirm-password');

        if (currentPassword === newPassword) {
          const { dialogChangePasswordNew } = this.refs;
          dialogChangePasswordNew.setCustomValidity('New password must be different.');
          dialogChangePasswordNew.reportValidity();
          return;
        }

        if (newPassword !== confirmPassword) {
          const { dialogChangePasswordConfirm } = this.refs;
          dialogChangePasswordConfirm.setCustomValidity('Password does not match.');
          dialogChangePasswordConfirm.reportValidity();
          return;
        }

        const ok = await changePassword(currentPassword, newPassword);
        if (!ok) {
          const { dialogChangePasswordCurrent } = this.refs;
          dialogChangePasswordCurrent.setCustomValidity('Password is incorrect.');
          dialogChangePasswordCurrent.reportValidity();
          return;
        }

        dialog.close();
      },
    },
    dialogImportDelegatesFile: {
      input({ currentTarget }) { this._importDelegatesFileName = currentTarget.value; },
      change({ currentTarget }) { this._importDelegatesFileName = currentTarget.value; },
    },
    toolsImportDelegates: {
      'mdw:hyperlink'(event) {
        event.preventDefault();
        event.stopPropagation();
        this.refs.dialogImportDelegates.querySelector('form').reset();
        this._importDelegatesFileName = '';
        this.refs.dialogImportDelegates.showModal();
      },
    },
    dialogImportDelegates: {
      input({ target }) { target.setCustomValidity(''); },
      change({ target }) { target.setCustomValidity(''); },
      async submit(event) {
        if (event.submitter?.value !== 'confirm') return;
        event.preventDefault();

        const dialog = /** @type {HTMLDialogElement} */ (event.currentTarget);
        const form = /** @type {HTMLFormElement} */ (event.target);
        const formData = new FormData(form);
        const importConfirm = formData.get('import-confirm');
        if (importConfirm !== 'import') {
          const el = form.elements['import-confirm'];
          el.setCustomValidity("Please type 'import' to confirm this action.");
          el.focus();
          return;
        }
        for (const el of form.elements) {
          el.disabled = true;
        }
        /** @type {HTMLInputElement} */
        const fileElement = form.elements.file;
        try {
          await uploadImportDelegates(fileElement.files[0]);
        } catch (e) {
          console.error(e);
        }
        for (const el of form.elements) {
          el.disabled = false;
        }
        dialog.close();
      },
    },
  })
  .rootEvents({
    'mdw:hyperlink'(event) {
      /** @type {string} */
      const href = event.detail.href;
      const url = new URL(href, window.location);
      console.log(url.pathname);
      if (!url.pathname.startsWith('/profile/')) return;
      // Handle
      event.stopPropagation();
      event.preventDefault();
      this.handleUrl(url);
    },
  })
  .on({
    constructed() {
      this._profile = { ...profile };
      profileUpdatedEvent.on((newProfile) => {
        this._profile = { ...newProfile };
      });
    },
    connected() {},
    disconnected() {},
  })
  .autoRegister('profile-page');
