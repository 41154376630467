import { apiFetch } from './api.js';
import { API_BASE_URL } from './constants.js';

/** @type {Map<number, Delegate>} */
export const delegatesCache = new Map();

const DELEGATE_API = new URL('delegates/', API_BASE_URL);
const DELEGATE_GROUP_API = new URL('delegategroups/', API_BASE_URL);

// /** @return {Delegate[]} */
// export function refreshDelegates() {
//   return [...delegatesCache.values()];
// }

/**
 * @param {Partial<Delegate & {q:string}>|string} filter
 * @return {Promise<Delegate[]>}
 */
export async function queryDelegates(filter = {}) {
  const url = new URL(DELEGATE_API);
  const params = typeof filter === 'string' ? { q: filter } : filter ?? {};

  if ('delegateId' in params) url.searchParams.append('delegateId', params.delegateId.toString(10));
  for (const key of ['roleSystemAdmin', 'roleAdmin', 'roleTicketAgent', 'roleScanner', 'rolePhoto', 'active']) {
    if (key in params) url.searchParams.append(key, params[key] ? 'true' : 'false');
  }
  for (const key of ['label', 'email', 'username', 'phoneNumber']) {
    if (key in params) url.searchParams.append(key, params[key]);
  }
  if ('groups' in params) {
    for (const group of params.groups) {
      if ('delegateGroupId' in group) url.searchParams.append('groupId', group.delegateGroupId.toString(10));
      if ('name' in group) url.searchParams.append('groupName', group.name);
      if ('type' in group) url.searchParams.append('groupType', group.type);
    }
  }
  if ('q' in params) {
    url.searchParams.append('q', params.q);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

export const refreshDelegates = queryDelegates;

/**
 * @param {number} delegateId
 * @return {Promise<Delegate|null>}
 */
export async function fetchDelegate(delegateId) {
  const url = new URL(delegateId.toString(10), DELEGATE_API);
  const response = await apiFetch(url);
  if (response.ok) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} delegateId
 * @return {Promise<boolean>} Returns true if deleted
 */
export async function deleteDelegate(delegateId) {
  const url = new URL(delegateId.toString(10), DELEGATE_API);
  const response = await apiFetch(url, {
    method: 'DELETE',
  });

  if (response.status === 410) return true;

  return false;
}

/**
 * @param {number} delegateId
 * @param {Partial<Delegate>} patchInfo
 * @return {Promise<Delegate>}
 */
export async function updateDelegate(delegateId, patchInfo) {
  const url = new URL(delegateId.toString(10), DELEGATE_API);
  const response = await apiFetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
    body: JSON.stringify(patchInfo),
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<Delegate>} addInfo
 * @return {Promise<Delegate>}
 */
export async function insertDelegate(addInfo) {
  const url = new URL(DELEGATE_API);
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(addInfo),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<Delegate>} delegate
 * @return {Promise<Delegate>}
 */
export async function createDelegate(delegate) {
  const response = await apiFetch(DELEGATE_API, {
    method: 'POST',
    body: JSON.stringify(delegate),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Object} options
 * @param {number} [options.delegateId]
 * @param {string} [options.ticketId]
 * @return {Promise<DelegateTicketHistory[]>}
 */
export async function queryDelegateTicketHistory({ delegateId, ticketId }) {
  const url = new URL(`delegates/${delegateId}/tickets`, API_BASE_URL);
  if (ticketId != null) {
    url.searchParams.set('ticketId', ticketId.toString());
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {Object} options
 * @param {number} options.delegateId
 * @param {string} options.ticketId
 * @return {Promise<boolean>}
 */
export async function assignDelegateTicket({ delegateId, ticketId }) {
  const url = new URL(`delegates/${delegateId}/assignticket`, API_BASE_URL);
  try {
    const response = await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      body: JSON.stringify({ ticketId }),
    });
    if (response.ok) return true;
  } catch {}
  return false;
}

/**
 * @param {Object} options
 * @param {number} options.delegateId
 * @param {string} options.ticketId
 * @return {Promise<boolean>}
 */
export async function removeDelegateTicket({ delegateId, ticketId }) {
  const url = new URL(`delegates/${delegateId}/removeticket`, API_BASE_URL);
  try {
    const response = await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      body: JSON.stringify({ ticketId }),
    });
    if (response.ok) return true;
  } catch {}
  return false;
}

/**
 * @param {number} delegateId
 * @return {Promise<boolean>}
 */
export async function resetDelegatePassword(delegateId) {
  const url = new URL(`delegates/${delegateId}/resetpassword`, API_BASE_URL);
  try {
    const response = await apiFetch(url, {
      method: 'POST',
    });
    if (response.ok) return true;
  } catch {}
  return false;
}

/**
 * @param {Object} options
 * @param {number} options.delegateId
 * @param {number} options.templateId
 * @param {string} options.ticketId
 * @return {Promise<boolean>}
 */
export async function emailDelegateTicket({ delegateId, templateId, ticketId }) {
  const url = new URL(`delegates/${delegateId}/templates/${templateId}/emailticket`, API_BASE_URL);
  try {
    const response = await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      body: JSON.stringify({ ticketId }),
    });
    if (response.ok) return true;
  } catch {}
  return false;
}

/**
 * @param {Object} options
 * @param {number} options.delegateId
 * @param {number} options.templateId
 * @param {string} options.ticketId
 * @return {URL}
 */
export function getViewTicketURL({ delegateId, templateId, ticketId }) {
  return new URL(`delegates/${delegateId}/templates/${templateId}/ticket.png?ticketId=${ticketId}`, API_BASE_URL);
}

/**
 * @param {Partial<DelegateGroup>} filter
 * @return {Promise<DelegateGroup[]>}
 */
export async function getGroups(filter = {}) {
  const url = new URL(DELEGATE_GROUP_API);
  if (filter.delegateGroupId) {
    url.searchParams.append('delegateGroupId', filter.delegateGroupId.toString(10));
  }
  if (filter.name) {
    url.searchParams.append('name', filter.name);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {Partial<DelegateGroupMember>} filter
 * @return {Promise<DelegateGroupMember[]>}
 */
export async function getGroupMembers({ delegateId, delegateGroupId } = {}) {
  let url;
  if (delegateId) {
    url = new URL(`delegates/${delegateId}/groups/${delegateGroupId || ''}`, API_BASE_URL);
  } else if (delegateGroupId) {
    url = new URL(`delegategroups/${delegateGroupId}/delegates/`, API_BASE_URL);
  } else {
    throw new Error('Invalid search.');
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {DelegateGroupMember} groupMember
 * @return {Promise<any>}
 */
export async function putGroupMember(groupMember) {
  const { delegateId, delegateGroupId } = groupMember;
  const url = new URL(`delegates/${delegateId}/groups/${delegateGroupId || ''}`, API_BASE_URL);

  await apiFetch(url, {
    method: 'PUT',
    body: JSON.stringify(groupMember),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
}

/**
 * @param {DelegateGroupMember} groupMember
 * @return {Promise<any>}
 */
export async function deleteGroupMember(groupMember) {
  const { delegateId, delegateGroupId } = groupMember;
  const url = new URL(`delegates/${delegateId}/groups/${delegateGroupId || ''}`, API_BASE_URL);

  await apiFetch(url, { method: 'DELETE' });
}

/**
 * @param {Partial<DelegateGroup>} filter
 * @return {Promise<DelegateGroup[]>}
 */
export async function queryGroups(filter = {}) {
  const url = new URL(DELEGATE_GROUP_API);
  if (filter.delegateGroupId) {
    url.searchParams.set('delegateGroupId', filter.delegateGroupId.toString(10));
  }
  if (filter.name) {
    url.searchParams.set('name', filter.name);
  }
  if (filter.type) {
    url.searchParams.set('type', filter.type);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {Partial<string>} filter
 * @return {Promise<string[]>}
 */
export async function queryGroupTypes(filter = '') {
  const url = new URL('delegategrouptypes/', API_BASE_URL);
  if (filter) {
    url.searchParams.set('type', filter);
  }

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {number} groupId
 * @return {Promise<DelegateGroup|null>}
 */
export async function fetchGroup(groupId) {
  const url = new URL(groupId.toString(10), DELEGATE_GROUP_API);
  const response = await apiFetch(url);
  if (response.ok) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} groupId
 * @return {Promise<boolean>} Returns true if deleted
 */
export async function deleteGroup(groupId) {
  const url = new URL(groupId.toString(10), DELEGATE_GROUP_API);
  const response = await apiFetch(url, {
    method: 'DELETE',
  });

  if (response.status === 410) return true;

  return false;
}

/**
 * @param {number} groupId
 * @param {Partial<DelegateGroup>} patchInfo
 * @return {Promise<DelegateGroup>}
 */
export async function updateGroup(groupId, patchInfo) {
  const url = new URL(groupId.toString(10), DELEGATE_GROUP_API);
  const response = await apiFetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
    body: JSON.stringify(patchInfo),
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {Partial<DelegateGroup>} addInfo
 * @return {Promise<DelegateGroup>}
 */
export async function createGroup(addInfo) {
  const url = new URL(DELEGATE_GROUP_API);
  const response = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(addInfo),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}

/**
 * @param {number} delegateId
 * @return {Promise<(JWEvent & {ticketIds: string[], templates: JWEventTemplate[]})[]>}
 */
export async function getDelegateEvents(delegateId) {
  const url = new URL(`delegates/${delegateId}/jwevents/`, API_BASE_URL);

  const serverResponse = await apiFetch(url);
  if (serverResponse.ok) {
    return await serverResponse.json();
  }
  return [];
}

/**
 * @param {File} data
 * @return {Promise<any>}
 */
export async function uploadImportDelegates(data) {
  const url = new URL('delegates/import', API_BASE_URL);

  const serverResponse = await apiFetch(url, {
    method: 'POST',
    body: data,
  });
  if (!serverResponse.ok) {
    throw new Error(serverResponse.statusText);
  }
}
